<template>
  <base-modal
    @close="close"
    :loading="submitting"
    title="Naturalize Schedule"
    dataCy="naturalize-schedule-modal"
  >
    <div class="max-w-610 space-y-6 p-8">
      <p class="text-muted">
        Naturalizing your schedule helps add some variance and make it feel more authentic. We can
        also update your currently scheduled posts to match your new schedule, or you can leave them
        as they are.
      </p>
      <div class="flex items-center justify-between">
        <div class="flex items-center">Adjust the currently scheduled posts to the new times</div>
        <base-toggle data-cy="move-posts-to-top" v-model="moveAllQueuedPostsToTop" />
      </div>
      <new-button
        type="primary"
        @click="naturalizeSchedule"
        data-cy="naturalize-my-schedule"
        class="ml-auto"
      >
        Naturalize my schedule
      </new-button>
    </div>
  </base-modal>
</template>

<script>
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { mapGetters } from 'vuex';

  export default {
    props: {
      show: Boolean,
    },
    name: 'natural-schedule-modal',
    data() {
      return {
        submitting: false,
        moveAllQueuedPostsToTop: false,
      };
    },
    methods: {
      close() {
        this.$emit('close');
      },
      naturalizeSchedule() {
        this.submitting = true;
        setTimeout(() => {
          this.$emit('naturalize-schedule', {
            moveAllQueuedPostsToTop: this.moveAllQueuedPostsToTop,
          });
          this.submitting = false;
          this.moveAllQueuedPostsToTop = false;
          this.close();
        }, 1000);
      },
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    },
    mixins: [SwalModalMixin],
  };
</script>
