// This function works well with Arabic and Persian, but it fails with Bengali and Devanagari
function normalizeStringNumber(input) {
  if (!input || typeof input !== 'string') {
    throw new Error('Input must be a string');
  }

  return input.replace(
    /[\u0660-\u0669\u06f0-\u06f9\u09e6-\u09ef\u0966-\u096f\u0e50-\u0e59]/gu,
    (char) => String(char.charCodeAt(0) & 0xf),
  );
}

export default normalizeStringNumber;
