<template>
  <div
    data-cy="queue-card-retweet"
    class="grid gap-4"
    :class="{ 'drag-hovered-slot border-2 border-blue-20': isDragEntered }"
    :draggable="true"
    @dragstart="dragStart(post, $event)"
    @dragend="dragEnd(post, $event)"
    @dragover="dragEnterNonEmptySlot(post, $event)"
    @dragleave="dragLeaveNonEmptySlot(post, $event)"
    @drop="dropNonEmptySlot(post, $event)"
  >
    <queue-card-container :canCollapse="Boolean(post.conditionalRetweetsConditions)">
      <template #header>
        <div class="flex items-center gap-2">
          <h4 data-cy="queue-card-time">{{ formatTimeWithUserLocale(postTimeMoment) }}</h4>

          <tooltip
            v-if="post.type === 'retweet' && post.conditionalRetweetsConditions"
            :content="displayIconMessage('Conditional Retweet', post)"
          >
            <div class="flex items-center gap-x-1 text-muted">
              <inline-svg class="h-5 w-5" src="/img/icons/retweet.svg" />
              <span
                class="normal-nums leading-none"
                v-html="displayIconCondition('conditionalRetweet', post)"
              />
            </div>
          </tooltip>
        </div>
      </template>
      <template v-slot:actions>
        <div class="flex items-center gap-x-5">
          <tooltip content="Post Now" v-if="canRetweetOthers(post)">
            <new-button data-cy="queue-post-now" type="custom" @click="postNow(post)">
              <inline-svg
                src="/img/icons/post-now-icon.svg"
                class="h-5 w-5 stroke-current hover:stroke-main-color-100"
              />
            </new-button>
          </tooltip>
          <tooltip content="Delete Retweet">
            <new-button type="custom" data-cy="queue-delete-post" @click="onDeleteThread(post)">
              <inline-svg
                class="h-5 w-5 stroke-current hover:stroke-danger-100"
                src="/img/icons/delete.svg"
              />
            </new-button>
          </tooltip>
        </div>
      </template>
      <template #default>
        <tweet-display
          v-if="post.originalTweetInfo"
          :profilePictureURL="post.originalTweetInfo.userProfilePictureURL"
          :tweet="post.originalTweetInfo.text"
          :username="post.originalTweetInfo.username"
          isRedirectable
        >
          <template #footer>
            <div class="flex items-center gap-x-2">
              <inline-svg class="h-4 w-4" src="/img/icons/retweet.svg" />
              <a
                target="_blank"
                :href="`https://twitter.com/${post.originalTweetInfo.username}/status/${post.tweetId}`"
              >
                View Original Tweet
              </a>
            </div>
          </template>
        </tweet-display>
      </template>

      <template #extra>
        <span
          class="flex h-8 w-8 items-center justify-center rounded-full bg-ds-button-secondary-background"
        >
          <inline-svg
            class="h-5 w-5 text-ds-button-secondary-label"
            src="/img/icons/new/twitter.svg"
          />
        </span>
      </template>
    </queue-card-container>
  </div>
</template>

<script>
  import QueueSlotMixin from '@/views/Mixins/QueueSlotMixin.vue';
  import RetweetMixin from '@/views/Mixins/RetweetMixin';
  import TweetDisplay from '@/components/TweetDisplay.vue';

  export default {
    name: 'QueueRetweetCard',
    mixins: [QueueSlotMixin, RetweetMixin],
    components: {
      TweetDisplay,
    },
  };
</script>
