<template>
  <div
    v-if="post && (post.tweets || post.thread)"
    :data-cy="post.slotType !== 'recurrent' ? 'queue-card-tweet' : 'queue-card-recurrent'"
    class="relative space-y-4"
    :class="{
      'drag-hovered-slot border-2 border-blue-20': isDragEntered,
    }"
    :draggable="post.slotType !== 'recurrent'"
    @dragstart="post.slotType !== 'recurrent' ? dragStart(post, $event) : null"
    @dragend="post.slotType !== 'recurrent' ? dragEnd(post, $event) : null"
    @dragover="dragOver"
    @dragleave="dragleave"
    @drop="drop"
  >
    <queue-card-container>
      <template #header>
        <!-- Card Title -->
        <div class="card-header flex items-center gap-2 text-base leading-6">
          <p data-cy="queue-card-time">
            {{ formatTimeWithUserLocale(postTimeMoment) }}
          </p>

          <span
            v-if="post.slotType === 'recurrent'"
            class="text-base lg:text-lg"
            :style="`color: ${post.recurrentPost.category.color}`"
          >
            Recurrent: {{ post.recurrentPost.category.name }}
          </span>
        </div>
        <!-- Card actions -->
        <div class="hidden h-5 items-center gap-x-3 md:flex md:gap-x-5" data-cy="actions-w">
          <template v-for="action in actions">
            <tooltip
              :key="action.tooltip"
              :content="action.tooltip"
              v-bind="{ ...(action.tooltipFooter ? { footer: action.tooltipFooter } : {}) }"
              v-if="action.show"
            >
              <new-button
                @click="action.action"
                :data-cy="action.dataCy"
                :type="action.isNormalButton ? 'primary' : 'custom'"
                size="sm"
              >
                <template v-if="action.isNormalButton">
                  <p>{{ action.tooltip }}</p>
                </template>
                <template v-else>
                  <inline-svg class="h-5 w-5" :class="action.class" :src="action.icon" />
                </template>
              </new-button>
            </tooltip>
          </template>
        </div>
        <div class="flex h-5 items-center gap-x-3 md:hidden md:gap-x-5">
          <div class="relative inline-block" v-click-outside="handleClickOutside">
            <new-button @click="isPopupVisible = !isPopupVisible" type="bordered" size="sm" icon>
              <inline-svg src="/img/icons/options-icon.svg" class="h-10 w-10 fill-main-color-100" />
            </new-button>
            <div v-if="isPopupVisible" class="absolute right-0 top-6 z-50">
              <div
                class="flex flex-col items-start gap-3 rounded-lg bg-ds-foreground p-4 shadow-tooltip"
              >
                <template v-for="action in actions">
                  <new-button
                    v-if="
                      !action.isNormalButton &&
                      action.show &&
                      action.uniqueLabel !== 'toggle-evergreen'
                    "
                    :key="action.tooltip"
                    @click="action.action"
                    :data-cy="action.dataCy"
                    type="custom"
                    size="sm"
                  >
                    <span class="flex w-56 items-center gap-3">
                      <inline-svg class="h-5 w-5" :class="action.class" :src="action.icon" />
                      <p class="text-sm font-light text-ds-button-icon">{{ action.tooltip }}</p>
                    </span>
                  </new-button>
                </template>
                <div class="border-t border-ds-outline-secondary pt-4">
                  <new-button
                    @click="addToEvergreenQueue(post)"
                    data-cy="queue-card-evergreen"
                    type="custom"
                    size="sm"
                  >
                    <span class="flex w-56 items-center justify-between">
                      <span class="flex items-center gap-3">
                        <inline-svg
                          class="h-5 w-5 stroke-ds-button-icon"
                          src="/img/icons/star-empty.svg"
                        />
                        <p class="text-sm font-light text-ds-button-icon">Set as an Evergreen</p>
                      </span>
                      <div
                        class="border relative h-5 w-8 overflow-hidden rounded-full"
                        :class="
                          isFavorite
                            ? ' border-ds-button-icon bg-ds-button-icon'
                            : 'border-ds-outline-primary bg-ds-outline-primary'
                        "
                      >
                        <div class="relative flex h-full w-full items-center justify-between">
                          <span
                            class="absolute top-0 z-40 h-full w-4 rounded-full bg-ds-button-controls-background"
                            :class="isFavorite ? 'left-0' : 'right-0'"
                          />
                        </div>
                      </div>
                    </span>
                  </new-button>
                </div>
              </div>
            </div>
          </div>
          <template v-for="action in actions">
            <tooltip
              :key="action.tooltip"
              :content="action.tooltip"
              v-bind="{ ...(action.tooltipFooter ? { footer: action.tooltipFooter } : {}) }"
              v-if="action.isNormalButton"
            >
              <new-button
                @click="action.action"
                :data-cy="action.dataCy"
                :type="action.isNormalButton ? 'primary' : 'custom'"
                size="sm"
              >
                <template v-if="action.isNormalButton">
                  <p>{{ action.tooltip }}</p>
                </template>
                <template v-else>
                  <inline-svg class="h-5 w-5" :class="action.class" :src="action.icon" />
                </template>
              </new-button>
            </tooltip>
          </template>
        </div>
      </template>
      <template #default>
        <div
          v-for="(tweet, index) in post.thread ? post.thread.tweets : post.tweets"
          :key="tweet.guid"
          class="relative w-full"
          :class="['space-y-3', index !== 0 && 'mt-6']"
        >
          <div class="flex items-center gap-3">
            <img
              :src="userProfile.photoURL"
              :alt="userProfile.username"
              class="h-10 w-10 rounded-full"
            />
            <div class="flex-grow flex min-w-0 flex-1 items-center gap-2">
              <span class="avatar-name">{{ userProfile.name }}</span>
              <span class="avatar-username">@{{ userProfile.username }}</span>
            </div>
          </div>

          <template v-if="post.thread">
            <div
              v-if="post.thread.tweets.length > 1"
              class="absolute ml-5 w-px bg-ds-outline-secondary"
              style="height: calc(100% - 44px)"
            />
          </template>
          <template v-else>
            <div
              v-if="post.tweets.length > 1"
              class="absolute ml-5 w-px bg-ds-outline-secondary"
              style="height: calc(100% - 44px)"
            />
          </template>

          <div class="ml-13">
            <!-- Reply  -->
            <tweet-display
              v-if="post.replyToTweetId"
              :profilePictureURL="post.replyToTweetInfo.userProfilePictureURL"
              :tweet="post.replyToTweetInfo.text"
              :userDisplayName="post.replyToTweetInfo.userDisplayName"
              :username="post.replyToTweetInfo.username"
              isRedirectable
            >
              <template #footer>
                <div class="flex items-center gap-x-2">
                  <inline-svg src="/img/icons/reply.svg" class="h-4 w-4" />
                  <a
                    target="_blank"
                    :href="`https://twitter.com/${post.replyToTweetInfo.username}/status/${post.replyToTweetId}`"
                  >
                    In reply to <span>{{ post.replyToTweetInfo.username }}</span>
                  </a>
                </div>
              </template>
            </tweet-display>

            <!-- Main Tweet -->
            <p
              class="whitespace-pre-wrap break-words text-base lg:text-lg"
              v-html="formatTweet(tweet.status)"
              dir="auto"
            />

            <!-- Quoted Tweet -->
            <tweet-display
              v-if="tweet.quoteTweetData"
              :class="tweet.status.length > 0 && 'mt-3'"
              :profilePictureURL="tweet.quoteTweetData.userProfilePictureURL"
              :tweet="tweet.quoteTweetData.text"
              :username="tweet.quoteTweetData.username"
            />

            <!-- Thread Finisher -->
            <tweet-display
              v-if="post.hasThreadFinisherTweet && tweet.count === post.tweets.length - 1"
              class="mt-3"
              :profilePictureURL="userProfile.photoURL"
              :tweet="post.tweets[0].status"
              :username="userProfile.username"
            />
            <!-- TweetShot for [Instagram, Facebook, LinkedIn] Posts  -->
            <tweet-display
              v-for="content in post.tweetshotContent"
              :key="content.tweetId"
              class="my-3"
              :profilePictureURL="userProfile.photoURL"
              :tweet="content.tweetText"
              :username="userProfile.username"
            />
            <queue-thumbnail
              v-if="post.thread"
              :media="getMedia(post.thread, index)"
              :marginTop="tweet.status.length > 0"
            />
            <queue-thumbnail
              v-else-if="post.tweets"
              :media="getMedia(post, index)"
              :marginTop="tweet.status.length > 0"
            />

            <poll-options :tweet="tweet" />
          </div>
        </div>
      </template>

      <template #extra>
        <div class="flex items-center gap-2">
          <template v-for="icon in footerIcons">
            <tooltip
              :key="icon.key"
              v-if="icon.show"
              :content="displayIconMessage(icon.key, post.thread || post)"
              :disabled="icon.disabled"
              :data-cy="icon.dataCy"
            >
              <span
                class="flex h-8 w-8 items-center justify-center rounded-full bg-ds-outline-secondary"
              >
                <inline-svg class="h-5 w-5 text-grey-40 dark:text-navy-50" :src="icon.path" />
              </span>
            </tooltip>
          </template>
        </div>
      </template>
    </queue-card-container>
  </div>
</template>

<script>
  import QueueSlotMixin from '@/views/Mixins/QueueSlotMixin.vue';
  import TweetDisplay from '@/components/TweetDisplay.vue';
  import QueueThumbnail from '@/views/QueueThumbnail';
  import PollOptions from '@/components/PollOptions';
  import lodash from 'lodash';

  export default {
    name: 'QueueTweetCard',
    components: {
      TweetDisplay,
      QueueThumbnail,
      PollOptions,
    },
    mixins: [QueueSlotMixin],
    data() {
      return {
        isPopupVisible: false,
      };
    },
    computed: {
      isFavorite() {
        return this.post.isFavorite || (this.post.thread && this.post.thread.isFavorite);
      },
      actions() {
        return [
          {
            uniqueLabel: 'delete-post',
            tooltip: 'Delete',
            show: this.post.slotType !== 'recurrent',
            icon: '/img/icons/delete.svg',
            class: 'stroke-ds-button-icon hover:stroke-danger-100',
            action: () => this.onDeleteThread(this.post),
            dataCy: 'queue-delete-post',
          },
          {
            uniqueLabel: 'pin-post',
            tooltip: this.displayIconMessage('Pin Post', this.post.thread || this.post),
            tooltipFooter: this.getPinPostTooltipFooterMessage(this.post),
            show:
              !['instagram-post', 'linkedin-post', 'facebook-post', 'threads-post'].includes(
                this.post.source,
              ) && this.post.slotType !== 'recurrent',
            icon: this.post.isPinned
              ? '/img/icons/new/pin-slash-outline.svg'
              : '/img/icons/new/pin-outline.svg',
            class: this.post.isPinned ? 'stroke-main-color-100' : 'stroke-ds-button-icon',
            action: () => this.pinPost(this.post),
            dataCy: 'queue-card-pin-post',
          },
          {
            uniqueLabel: 'post-now',
            tooltip: 'Post Now',
            show: this.post.slotType !== 'recurrent',
            icon: '/img/icons/post-now-icon.svg',
            class: 'stroke-ds-button-icon',
            action: () => this.postNow(this.post),
            dataCy: 'queue-post-now',
          },
          {
            uniqueLabel: 'skip-recurrent-slot',
            show: this.post.slotType === 'recurrent',
            tooltip: 'Skip recurrent slot',
            dataCy: 'queue-skip-post',
            action: () => this.skipRecurrentSlot(),
            class: 'fill-current w-5 h-5 hover:fill-main-color-100',
            icon: '/img/icons/skip.svg',
          },
          {
            uniqueLabel: 'replace-recurrent-slot',
            show: this.post.slotType === 'recurrent',
            tooltip: 'Replace recurrent slot',
            dataCy: 'queue-replace-post',
            action: () => this.replaceRecurrentSlot(),
            class: 'fill-current h-5 w-5 hover:fill-main-color-100',
            icon: '/img/icons/replace.svg',
          },
          {
            uniqueLabel: 'toggle-evergreen',
            tooltip: this.displayIconMessage('Evergreen Post', this.post.thread || this.post),
            show: !['instagram-post', 'linkedin-post', 'facebook-post', 'threads-post'].includes(
              this.post.source,
            ),
            icon: '/img/icons/star-empty.svg',
            class: this.isFavorite ? 'fill-success-100' : 'stroke-ds-button-icon',
            action: () => this.addToEvergreenQueue(this.post),
            dataCy: 'queue-card-evergreen',
          },
          {
            uniqueLabel: 'edit-post',
            tooltip: 'Edit',
            show: this.post.slotType !== 'recurrent' && !this.post.autoDM,
            icon: '/img/icons/edit.svg',
            class: '',
            action: () => this.newEditPostAction(),
            dataCy: 'queue-edit-post',
            isNormalButton: true,
          },
        ];
      },
      footerIcons() {
        return [
          {
            show: !['instagram-post', 'linkedin-post', 'facebook-post', 'threads-post'].includes(
              this.post.source,
            ),
            path: '/img/icons/new/socials/twitter.svg',
            dataCy: 'queue-card-twitter-icon',
            disabled: true,
            key: 'twitter',
          },
          {
            show:
              this.post.linkedIn ||
              this.post.source === 'linkedin-post' ||
              !lodash.isEmpty(lodash.get(this.post, 'thread.linkedIn', null)),
            path: '/img/icons/new/linkedin.svg',
            dataCy: 'queue-card-linkedin-icon',
            disabled: this.post.source === 'linkedin-post',
            key: 'LinkedIn',
          },
          {
            show:
              this.post.facebook ||
              this.post.source === 'facebook-post' ||
              !lodash.isEmpty(lodash.get(this.post, 'thread.facebook', null)),
            path: '/img/icons/new/facebook.svg',
            dataCy: 'queue-card-facebook-icon',
            disabled: this.post.source === 'facebook-post',
            key: 'Facebook',
          },
          {
            show:
              this.post.shareOnInstagram ||
              this.post.source === 'instagram-post' ||
              lodash.get(this.post, 'thread.shareOnInstagram', false),
            path: '/img/icons/new/instagram.svg',
            dataCy: 'queue-card-instagram-icon',
            disabled: this.post.source === 'instagram-post',
            key: 'Instagram',
          },
          {
            show:
              this.post.autoplug || !lodash.isEmpty(lodash.get(this.post, 'thread.autoplug', null)),
            path: '/img/icons/new/autoplug.svg',
            dataCy: 'queue-card-follow-up-icon',
            disabled: false,
            key: 'Autoplug',
          },
          {
            show:
              this.post.conditionalRetweetsConditions ||
              !lodash.isEmpty(lodash.get(this.post, 'thread.conditionalRetweetsConditions', null)),
            path: '/img/icons/retweet.svg',
            dataCy: 'queue-card-auto-retweet-icon',
            disabled: false,
            key: 'Conditional Retweet',
          },
          {
            show:
              this.post.delayBetweenTweets ||
              !lodash.isEmpty(lodash.get(this.post, 'thread.delayBetweenTweets', null)),
            path: '/img/icons/delay.svg',
            dataCy: 'queue-card-delay-icon',
            disabled: false,
            key: 'Delay Message',
          },
          {
            show: this.post.autoDM || !lodash.isEmpty(lodash.get(this.post, 'thread.autoDM', null)),
            path: '/img/icons/new/autodm.svg',
            dataCy: 'queue-card-dm-icon',
            disabled: false,
            key: 'autoDM',
          },
          {
            show:
              this.post.source === 'threads-post' ||
              this.post.threads ||
              !lodash.isEmpty(lodash.get(this.post, 'thread.threads', null)),
            path: '/img/icons/new/socials/threads.svg',
            dataCy: 'queue-card-threads-icon',
            disabled: this.post.source === 'threads-post',
            key: 'Threads',
          },
        ];
      },
    },
    methods: {
      getMedia(source, index) {
        const tweetReelRenderedVideoURL = lodash.get(source, 'tweetReel.outputFileUrl', null);
        const tweets = lodash.get(source, 'tweets', null);
        if (tweetReelRenderedVideoURL) {
          return [{ url: tweetReelRenderedVideoURL, type: 'reel' }];
        } else if (tweets) {
          return source.tweets[index].media;
        }
        return [];
      },
      dragOver(event) {
        if (
          this.arePostAndSlotOfTheSameType(this.draggedPost, this.post) &&
          this.post.slotType !== 'recurrent'
        ) {
          this.dragEnterNonEmptySlot(this.post, event);
        }
      },
      dragleave(event) {
        if (this.post.slotType !== 'recurrent') {
          this.dragLeaveNonEmptySlot(this.post, event);
        }
      },
      drop(event) {
        if (
          this.arePostAndSlotOfTheSameType(this.draggedPost, this.post) &&
          this.post.slotType !== 'recurrent'
        ) {
          this.dropNonEmptySlot(this.post, event);
        }
      },
      handleClickOutside() {
        this.isPopupVisible = false;
      },
    },
  };
</script>

<style scoped>
  .card-header {
    font-weight: 450;
  }
</style>
