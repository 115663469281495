var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-card',{staticClass:"relative",attrs:{"bordered":false,"lightBordered":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.$slots.header)?_c('div',{class:['card-header group', { 'bordered-bottom': _vm.showBody, 'rounded-lg': !_vm.showBody }]},[_vm._t("header"),(_vm.canCollapse)?_c('div',{staticClass:"flex items-center gap-x-5"},[_c('tooltip',{attrs:{"content":_vm.isCollapsed ? 'Show Tweet' : 'Hide Tweet'}},[_c('new-button',{attrs:{"type":"custom"},on:{"click":function($event){_vm.isCollapsed = !_vm.isCollapsed}}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.isCollapsed ? 'Open Card' : 'Close Card'))]),_c('inline-svg',{class:[
                'transition h-5 w-5 transform fill-current duration-500 ease-in-out hover:fill-main-color-100',
                {
                  '-rotate-90': _vm.isCollapsed,
                  'rotate-1': !_vm.isCollapsed,
                },
              ],attrs:{"src":"/img/icons/chevron-down.svg"}})],1)],1),_vm._t("actions")],2):_vm._e(),(!_vm.canCollapse)?_vm._t("actions"):_vm._e()],2):_vm._e()]},proxy:true},(_vm.showBody)?{key:"body",fn:function(){return [_c('div',{ref:"body",staticClass:"card-body bg-ds-background",class:{ 'max-h-300 overflow-y-hidden rounded-b-lg': _vm.isExpandable && _vm.showExpand },attrs:{"data-cy":"queue-card-body"}},[_vm._t("default")],2)]},proxy:true}:null,(_vm.showBody)?{key:"extra",fn:function(){return [_c('div',{staticClass:"flex w-full items-end justify-between rounded-b-lg bg-ds-background px-8 pb-4",class:_vm.isExpandable && _vm.showExpand && 'fade-effect absolute bottom-0 h-24'},[_vm._t("extra"),(_vm.isExpandable)?_c('div',[(_vm.showExpand)?_c('new-button',{attrs:{"type":"secondary"},on:{"click":function($event){_vm.showExpand = false}}},[_c('span',[_vm._v("Show more")])]):_c('new-button',{attrs:{"type":"secondary"},on:{"click":function($event){_vm.showExpand = true}}},[_c('span',[_vm._v("Show less")])])],1):_vm._e()],2)]},proxy:true}:null],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }