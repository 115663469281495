<script>
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import controller from '@/controller';

  export default {
    methods: {
      deleteThread(thread) {
        const action = (() => {
          if (this.$route.meta.id === 'history' || this.$route.meta.id === 'evergreen_posts') {
            return thread.type === 'post' ? 'delete this tweet' : 'unretweet this tweet';
          } else if (this.$route.meta.id === 'drafts') {
            return 'delete this post'
          } else {
            return 'unschedule this post';
          }
        })();

        return this.swalModal({
          title: 'Delete Post',
          text: `Are you sure you want to ${action}?`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete this post',
          preConfirm: () => {
            return thread.deleteFromFirestore(this.currentUser, this.userProfile.uid)
              .catch(error => {
                this.swalModal({
                  title: 'Sorry',
                  text: 'An error has occurred while deleting the post.',
                  type: 'warning',
                });
                console.error(error);
              });
          }
        })
      },
      deleteAllQueuedPosts() {
        this.chainMultipleSwalModals([
          {
            title: 'Warning',
            text: `Are you sure you want to delete all the queued posts? This action is irreversible.`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
          },
          {
            title: 'Warning',
            text: `Are you really, really sure? Your scheduled posts will be deleted and unrecoverable.`,
            showCancelButton: true,
            confirmButtonText: 'Yes, please!',
            preConfirm: () => {
              controller.deleteAllQueuedPosts(this.currentUser, this.userProfile);
            },
          },
        ]);
      },
    },
    mixins:[SwalModalMixin]
  };
</script>