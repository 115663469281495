<script>
  import QueueCardContainer from '@/components/Queue/QueueCardContainer.vue';
  import DragAndDropMixin from '@/views/Mixins/DragAndDropMixin';
  import TimeFormatterMixin from '@/views/Mixins/TimeFormatterMixin';
  import { displayIconMessage } from '@/util/displayIconMessage';
  import dao from '@/dao';
  import lodash from 'lodash';
  import moment from 'moment';
  import { mapGetters } from 'vuex';
  import { formatTweet } from '@/util/formatTweet';
  import SwalModalMixin from './SwalModalMixin.vue';
  import { EventBus } from '@/event-bus';

  export default {
    mixins: [DragAndDropMixin, TimeFormatterMixin, SwalModalMixin],
    components: {
      QueueCardContainer,
    },
    props: {
      post: {
        type: Object,
        required: true,
      },
      userTimezone: {
        type: String,
        required: true,
      },
      draggedPost: {
        type: Object,
        required: false,
        default: null,
      },
      postTime: {
        type: String,
        required: true,
      },
    },
    computed: {
      postTimeMoment() {
        return moment(this.postTime).tz(this.userTimezone);
      },
      ...mapGetters({ userProfile: 'getUserProfile' }),
    },
    methods: {
      displayIconMessage,
      arePostAndSlotOfTheSameType(draggedPost, slot) {
        const getPostSlotType = (post) => {
          if (!post) {
            return null;
          }

          if (post.source === 'instagram-post') {
            return 'instagram';
          } else if (post.source === 'linkedin-post') {
            return 'linkedin';
          } else if (post.source === 'facebook-post') {
            return 'facebook';
          } else if (post.source === 'threads-post') {
            return 'threads';
          } else {
            return null;
          }
        };

        const postSlotType = getPostSlotType(draggedPost);
        const slotType = lodash.get(slot, 'id')
          ? getPostSlotType(slot)
          : lodash.get(slot, 'slotType', null);

        return postSlotType === slotType;
      },
      getPinPostTooltipFooterMessage(post) {
        return post.isPinned
          ? 'Include this post while shuffling queue and moving all posts to the top'
          : 'Exclude this post while shuffling queue and moving all posts to the top';
      },
      addToEvergreenQueue: function (post) {
        if (post.thread) {
          post.thread.toggleFavorite();
        } else {
          post.toggleFavorite();
        }
        this.$forceUpdate();
      },
      pinPost(post) {
        dao.posts.togglePinPost(post.id, post.isPinned);
        this.$forceUpdate();
      },
      newEditPostAction() {
        this.$emit('edit-post');
      },
      onDeleteThread(post) {
        this.$emit('delete-thread', post);
      },
      postNow(post) {
        this.$emit('post-now', post);
      },
      formatTweet(tweet) {
        return formatTweet(tweet);
      },
      displayIconCondition(type, post) {
        switch (type) {
          case 'conditionalRetweet': {
            const globalConditionalRetweetsConditions = lodash.get(
              this,
              'userProfile.settings.conditionalRetweetsConditions'
            );

            const conditionalRetweets = post.conditionalRetweetsConditions
              ? post.conditionalRetweetsConditions
              : globalConditionalRetweetsConditions;

            if (!conditionalRetweets) {
              return null;
            }

            return Number.isInteger(conditionalRetweets.minFavoritesThreshold)
              ? `Auto-RT (${conditionalRetweets.minFavoritesThreshold} likes required)`
              : `Auto-RT (${conditionalRetweets.minRetweetsThreshold} retweets required)`;
          }
          default:
            return null;
        }
      },
      async skipRecurrentSlot() {
        const result = await this.swalModal({
          title: 'Warning',
          html: `Are you sure you want to skip this slot?`,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        });

        if (result.isConfirmed) {
          try {
            await dao.categories.skipTime(
              this.post.recurrentPost.category.id,
              this.post.time.toDate()
            );
            this.$notify({
              type: 'success',
              message: 'Slot skipped successfully!',
            });
          } catch {
            this.swalModal({
              title: 'Error',
              html: 'Something went wrong while skipping the recurrent slot. Please try again later.',
            });
          }
        }
      },
      async restoreRecurrentSlot() {
        try {
          await dao.categories.restoreTime(
            this.post.recurrentPost.category.id,
            this.post.time.toDate()
          );
          this.$notify({
            type: 'success',
            message: 'Slot restored successfully!',
          });
        } catch {
          this.swalModal({
            title: 'Error',
            html: 'Something went wrong while restoring the recurrent slot. Please try again later.',
          });
        }
      },
      async replaceRecurrentSlot() {
        const result = await this.swalModal({
          title: 'Warning',
          html: `Are you sure you want to replace this slot?`,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        });

        if (result.isConfirmed) {
          try {
            await dao.categories.skipTime(
              this.post.recurrentPost.category.id,
              this.post.time.toDate()
            );

            EventBus.$emit('open-new-composer', {
              time: this.postTimeMoment,
              source: {
                name: 'Queue',
              },
            });
          } catch {
            this.swalModal({
              title: 'Error',
              html: 'Something went wrong while replacing the recurrent slot. Please try again later.',
            });
          }
        }
      },
    },
  };
</script>
