<template>
  <base-modal @close="$emit('close')" :loading="isSubmitting || isLoadingPreview">
    <div class="grid max-w-610 gap-6 p-8">
      <div class="flex items-center gap-4">
        <div class="flex h-12 w-12 items-center justify-center rounded-full bg-main-color-100">
          <inline-svg src="/img/icons/refresh-2.svg"></inline-svg>
        </div>
        <span data-cy="retweet-modal-header" class="text-2xl font-bold">Schedule a retweet</span>
      </div>
      <div>
        <div class="flex items-center space-x-1 text-lg">
          <span class="w-fit">Scheduling for</span>
          <input
            class="w-fit"
            data-cy="retweet-modal-date"
            :min="minDate"
            type="datetime-local"
            v-model="time"
          />
        </div>
        <div
          v-if="getTimeError(time)"
          class="pb-4 text-badge-error"
          v-html="getTimeError(time)"
        ></div>
      </div>
      <div>
        <base-text-input
          placeholder="Past URL of the tweet"
          v-model="link"
          data-cy="retweet-modal-input"
          :error="error"
        />
      </div>

      <new-alert class="mt-6" v-if="originalTweetInfo && !canRetweetOthers(originalTweetInfo)">
        Only the {{ getPlanLabel('premium') }} plan permits that you schedule retweets of other
        users.<br />
        You can schedule this retweet but it won't be published unless you
        <router-link to="/billing" class="text-main-color-100">
          upgrade your subscription
        </router-link>
        to the {{ getPlanLabel('premium') }} plan.
      </new-alert>

      <div data-cy="retweet-modal-original-tweet" v-if="originalTweetInfo && tweet">
        <tweet-preview :originalTweetInfo="originalTweetInfo" />
      </div>

      <div class="flex items-center justify-between">
        <new-button @click="$emit('close')" type="light" data-cy="retweet-modal-close">
          Close
        </new-button>
        <new-button
          :disabled="!canRetweetBeScheduled()"
          @click="scheduleRetweet()"
          data-cy="retweet-modal-add-to-queue"
        >
          Add to queue
        </new-button>
      </div>
    </div>
  </base-modal>
</template>

<script>
  import lodash from 'lodash';
  import moment from 'moment';
  import 'moment-timezone';
  import NewThreadMixin from '@/views/Mixins/NewThreadMixin.vue';
  import controller from '@/controller';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { validateLink } from '@/util/validateLink';
  import { decodeTweetText } from '@/util/decodeTweetText';
  import { formatTweet } from '@/util/formatTweet';
  import { Retweet } from '../../models/Retweet';
  import { mapState } from 'vuex';
  import TweetPreview from '@/components/TweetPreview.vue';
  import RetweetMixin from '@/views/Mixins/RetweetMixin.vue';
  import CustomerStatusMixin from '../Mixins/CustomerStatusMixin.vue';

  export default {
    components: { TweetPreview },
    props: {
      timeFromParent: {
        type: Object,
        default: null,
      },
    },
    created() {
      this.time = this.timeFromParent.format('YYYY-MM-DDTHH:mm');
      this.getLinkFromTheClipboard();
    },
    computed: {
      ...mapState(['schedule']),
      minDate() {
        return moment().format('YYYY-MM-DDThh:mm');
      },
    },
    data() {
      return this.initialState();
    },
    methods: {
      formatTweet,
      async getLinkFromTheClipboard() {
        if (!lodash.get(navigator, 'clipboard.readText')) return;
        const text = await navigator.clipboard.readText();
        const { link, isValid } = validateLink(text);

        if (!isValid) return;

        this.$nextTick(() => {
          this.link = this.formatTweetLink(link);
        });
      },
      initialState() {
        return {
          originalTweetInfo: null,
          originalTweetUsername: '',
          error: null,
          isLoadingPreview: false,
          tweet: null,
          link: '',
          time: null,
          isSubmitting: false,
        };
      },
      async scheduleRetweet() {
        if (!this.canRetweetBeScheduled()) {
          return;
        }

        const tweetId = this.tweet.id;
        const time = moment.tz(this.time, this.timezone);

        const usersSameRetweetSlots = this.schedule.getAllSlots().filter((timeSlot) => {
          return (
            timeSlot.type == 'retweet' &&
            timeSlot.originalTweetInfo.userTwitterId === this.userProfile.twitterId &&
            timeSlot.tweetId === tweetId
          );
        });

        if (usersSameRetweetSlots.length >= 3) {
          const timesFromRetweetTimeSlots = usersSameRetweetSlots.map((timeSlot) => timeSlot.time);
          const latestTimeFromTimeSlots = moment.max([time, ...timesFromRetweetTimeSlots]);

          if (moment(latestTimeFromTimeSlots).diff(this.originalTweetInfo.time, 'hours') <= 72) {
            return this.swalModal({
              title: 'Sorry',
              text: 'You cannot schedule a retweet of your own tweet more than 3 times within 72 hours of publishing.',
              type: 'warning',
            });
          }
        }

        this.isSubmitting = true;

        const retweet = Retweet.newRetweet(tweetId, time, this.userProfile, this.originalTweetInfo);
        try {
          await retweet.saveToFirestore(this.$eventStore);

          this.$notify({ type: 'success', message: 'Retweet successfully scheduled!' });
          this.resetModal();
          this.$emit('close');
        } catch {
          this.swalModal({
            title: 'Sorry',
            text: `An error has occurred while saving the retweet.`,
            type: 'warning',
          });
        } finally {
          this.isSubmitting = false;
        }
      },
      canRetweetBeScheduled(shouldIgnoreTime = false) {
        return (
          !this.isSubmitting &&
          (shouldIgnoreTime ? null : this.isTimeValid(this.time)) &&
          this.originalTweetInfo
        );
      },
      resetModal() {
        Object.assign(this.$data, this.initialState());
      },
    },
    watch: {
      link: lodash.debounce(async function (val) {
        this.tweet = null;
        this.originalTweetInfo = null;
        this.originalTweetUsername = '';
        this.error = null;

        if (!val) return;

        const { tweetId, isValid, error } = validateLink(val);

        if (!isValid) {
          this.error = error;
          return;
        }

        this.isLoadingPreview = true;
        try {
          const result = await controller.getTweet(tweetId, this.currentUser, this.userProfile.uid);
          this.tweet = result.data;

          const userFromTwitter = lodash.get(result, 'includes.users', [{}])[0];

          this.originalTweetInfo = {
            username: userFromTwitter.username,
            userDisplayName: userFromTwitter.name,
            userProfilePictureURL: userFromTwitter.profile_image_url,
            userTwitterId: userFromTwitter.id,
            text: decodeTweetText(this.tweet.text),
            createdAt: new Date(this.tweet.created_at),
            id: this.tweet.id,
          };
          this.originalTweetUsername = userFromTwitter.username;
          this.currentUserName = this.userProfile.username;
        } catch (error) {
          if (error.response && error.response.status === 404) {
            this.error = 'Tweet not found';
          }
        } finally {
          this.isLoadingPreview = false;
        }
      }, 500),
    },
    mixins: [NewThreadMixin, SwalModalMixin, RetweetMixin, CustomerStatusMixin],
  };
</script>
