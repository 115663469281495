<template>
  <div class="space-y-6">
    <div class="flex w-full items-center justify-between gap-x-2">
      <div class="flex-grow flex min-w-0 items-center gap-2">
        <a
          :href="`https://twitter.com/${originalTweetInfo.username}`"
          target="_blank"
          tabindex="-1"
          class="flex-shrink-0"
        >
          <img
            :alt="`${originalTweetInfo.userDisplayName}'s profile picture`"
            :src="originalTweetInfo.userProfilePictureURL"
            class="avatar-img-sm"
          />
        </a>
        <div
          class="flex min-w-0 flex-col items-start gap-x-2 md:flex-row md:flex-wrap md:items-center md:justify-between"
        >
          <a
            class="avatar-name"
            :href="`https://twitter.com/${originalTweetInfo.username}`"
            target="_blank"
            tabindex="-1"
            >{{ originalTweetInfo.userDisplayName }}</a
          >
          <a
            class="avatar-username"
            :href="'https://twitter.com/' + originalTweetInfo.username"
            target="_blank"
            tabindex="-1"
            >@{{ originalTweetInfo.username }}</a
          >
        </div>
      </div>
      <div v-if="$slots.action">
        <slot name="action"></slot>
      </div>
    </div>
    <div
      class="max-w-lg whitespace-pre-wrap break-words text-lg leading-6"
      v-html="formatTweet(originalTweetInfo.text)"
      dir="auto"
      data-cy="tweet-preview-text"
    ></div>
    <div class="feed-item-tweet-time flex justify-end">
      <a
        target="_blank"
        :href="`https://twitter.com/${originalTweetInfo.username}/status/${originalTweetInfo.id}`"
        tabindex="-1"
      >
        {{ originalTweetInfo.createdAt | formatCreatedAt }}
      </a>
      <a
        class="view-on-twitter ml-2"
        target="_blank"
        :href="`https://twitter.com/${originalTweetInfo.username}/status/${originalTweetInfo.id}`"
        tabindex="-1"
      >
        View on Twitter
      </a>
    </div>
  </div>
</template>

<script>
  import { formatTweet } from '@/util/formatTweet';
  import moment from 'moment';

  export default {
    props: {
      originalTweetInfo: {
        type: Object,
        default: null,
      },
    },
    methods: {
      formatTweet,
    },
    filters: {
      formatCreatedAt: function (value) {
        return moment(value).format('h:mm A · MMM DD, YYYY');
      },
    },
  };
</script>
