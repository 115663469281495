<template>
  <div v-if="wasOnboardingChecklistDismissed && featureToDisplay && isPlanPriceFetchedIfNecessary">
    <div class="flex flex-col space-y-4 text-base mb-6 border border-divider py-4 px-6 rounded-lg">
      <div
        v-if="shouldFeatureReminderBeDisplayed('followHypefuryOnTwitter')"
        class="flex items-center space-x-2"
      >
        <!-- follow-hypefury-on-twitter -->
        <span v-if="isFollowingHypefuryTwitterAccount"
          >Following @Hypefury account, please wait...</span
        >
        <span v-if="!isFollowingHypefuryTwitterAccount">
          How about you follow Hypefury on Twitter?
        </span>
        <div class="flex space-x-2 items-center">
          <span
            v-if="!isFollowingHypefuryTwitterAccount"
            @click="followHypefuryTwitterAccount"
            class="cursor-pointer underline text-accent"
          >
            Follow
          </span>
          <span
            v-if="!isFollowingHypefuryTwitterAccount"
            class="cursor-pointer underline text-muted text-xs"
            @click="
              () => {
                hideFeature('followHypefuryOnTwitter');
                $eventStore.featureReminder.clickIgnore('followHypefuryOnTwitter');
              }
            "
            >Ignore</span
          >
        </div>
      </div>
      <div
        v-if="shouldFeatureReminderBeDisplayed('gumroadSalesInactivity')"
        class="flex items-center space-x-2"
      >
        <!-- Gumroad-connected -->
        <span>Hey! It's been a while since you ran a Gumroad sale!</span>
        <div class="flex space-x-2 items-center">
          <router-link
            to="/gumroad-sales"
            @click.native="
              $eventStore.featureReminder.gumroadSalesInactivityVisitGumroadSalesPage()
            "
          >
            Let's make some cash!!
          </router-link>
          <span
            class="cursor-pointer underline text-muted text-xs"
            @click="
              () => {
                hideFeature('gumroadSalesInactivity');
                $eventStore.featureReminder.clickIgnore('gumroadSalesInactivity');
              }
            "
            >Ignore</span
          >
        </div>
      </div>
      <div
        v-if="shouldFeatureReminderBeDisplayed('gumroadNotConnected')"
        class="flex items-center space-x-2"
      >
        <!-- Gumroad-not-connected -->
        <span>
          Selling on Gumroad? Connect your seller account to be able to run automated sales.
          Hypefury will also display your Gumroad revenue in the sidebar.
        </span>
        <div class="flex space-x-2 items-center">
          <router-link
            to="/settings#gumroad"
            @click.native="$eventStore.featureReminder.gumroadNotConnectedVisitSettingsPage()"
          >
            Connect
          </router-link>
          <span class="text-muted text-xs">–</span>
          <span
            class="cursor-pointer underline text-muted text-xs"
            @click="
              () => {
                hideFeature('gumroadNotConnected');
                $eventStore.featureReminder.clickIgnore('gumroadNotConnected');
              }
            "
            >Ignore</span
          >
        </div>
      </div>
      <div
        v-if="shouldFeatureReminderBeDisplayed('linkedInInactivity')"
        class="flex items-center space-x-2"
      >
        <!-- LinkedIn-connected -->
        <span>
          You haven't crossposted your tweets to LinkedIn for a while. Enable
          <em>sharing to LinkedIn</em> the next time you schedule something!
        </span>
        <div class="flex space-x-2 items-center">
          <span
            class="cursor-pointer underline text-muted text-xs"
            @click="
              () => {
                hideFeature('linkedInInactivity');
                $eventStore.featureReminder.clickIgnore('linkedInInactivity');
              }
            "
            >Got it!</span
          >
        </div>
      </div>
      <div
        v-if="shouldFeatureReminderBeDisplayed('linkedInNotConnected')"
        class="flex items-center space-x-2"
      >
        <!-- LinkedIn-not-connected -->
        <span>
          Did you know that you could crosspost your tweets to LinkedIn?
        </span>
        <div class="flex space-x-2 items-center">
          <router-link
            to="/settings#linkedin"
            @click.native="$eventStore.featureReminder.linkedInNotConnectedVisitSettingsPage()"
          >
            Connect your LinkedIn account
          </router-link>
          <span class="text-muted text-xs">–</span>
          <span
            class="cursor-pointer underline text-muted text-xs"
            @click="
              () => {
                hideFeature('linkedInNotConnected');
                $eventStore.featureReminder.clickIgnore('linkedInNotConnected');
              }
            "
            >Ignore</span
          >
        </div>
      </div>
      <div
        v-if="shouldFeatureReminderBeDisplayed('instagramInactivity')"
        class="flex items-center space-x-2"
      >
        <!-- instagram-connected -->
        <span>
          You haven't crossposted your tweets to IG for a while. Enable <em>sharing to IG</em> the
          next time you schedule something!
        </span>
        <div class="flex space-x-2 items-center">
          <span
            class="cursor-pointer underline text-muted text-xs"
            @click="
              () => {
                hideFeature('instagramInactivity');
                $eventStore.featureReminder.clickIgnore('instagramInactivity');
              }
            "
          >
            Will do!
          </span>
        </div>
      </div>
      <div
        v-if="shouldFeatureReminderBeDisplayed('instagramNotConnected')"
        class="flex items-center space-x-2"
      >
        <!-- Instagram-not-connected -->
        <span>
          Are you on IG? Connect your page to Hypefury so it can post your tweets on IG! (only when
          you ask it to)
        </span>
        <div class="flex space-x-2 items-center">
          <router-link
            to="/settings#instagram"
            @click.native="$eventStore.featureReminder.instagramNotConnectedVisitSettingsPage()"
          >
            Connect your account
          </router-link>
          <span class="text-muted text-xs">–</span>
          <span
            class="cursor-pointer underline text-muted text-xs"
            @click="
              () => {
                hideFeature('instagramNotConnected');
                $eventStore.featureReminder.clickIgnore('instagramNotConnected');
              }
            "
            >Ignore</span
          >
        </div>
      </div>
      <div
        v-if="shouldFeatureReminderBeDisplayed('facebookInactivity')"
        class="flex items-center space-x-2"
      >
        <!-- Facebook-connected -->
        <span>
          You haven't crossposted your tweets to Facebook for a while. Enable
          <em>sharing to Facebook</em> the next time you schedule something!
        </span>
        <div class="space-x-2">
          <span
            class="cursor-pointer underline text-muted text-xs"
            @click="
              () => {
                hideFeature('facebookInactivity');
                $eventStore.featureReminder.clickIgnore('facebookInactivity');
              }
            "
            >Got it!</span
          >
        </div>
      </div>
      <div
        v-if="shouldFeatureReminderBeDisplayed('facebookNotConnected')"
        class="flex items-center space-x-2"
      >
        <!-- Facebook-not-connected -->
        <span>
          Got a Facebook page or a group? Connect it so you can share your tweet on Facebook too!
        </span>
        <div class="flex space-x-2 items-center">
          <router-link
            to="/settings#facebook"
            @click.native="$eventStore.featureReminder.facebookNotConnectedVisitSettingsPage()"
          >
            Connect your account
          </router-link>
          <span class="text-muted text-xs">–</span>
          <span
            class="cursor-pointer underline text-muted text-xs"
            @click="
              () => {
                hideFeature('facebookNotConnected');
                $eventStore.featureReminder.clickIgnore('facebookNotConnected');
              }
            "
            >Ignore</span
          >
        </div>
      </div>
      <div
        v-if="shouldFeatureReminderBeDisplayed('evergreenRetweetsNotEnabled')"
        class="flex items-center space-x-2"
      >
        <!-- AutoRTs-not-enabled -->
        <span>
          How about making empty slots act as evergreen ones?<br />
          That would keep your account active when you're away while sharing value with your new
          followers.
        </span>
        <div class="flex space-x-2 items-center">
          <router-link :to="{path:`/queue#schedule`}">Let's go!</router-link>
          <span class="text-muted text-xs">–</span>
          <span
            class="cursor-pointer underline text-muted text-xs"
            @click="
              () => {
                hideFeature('evergreenRetweetsNotEnabled');
                $eventStore.featureReminder.clickIgnore('evergreenRetweetsNotEnabled');
              }
            "
          >
            No
          </span>
        </div>
      </div>
      <div
        v-if="shouldFeatureReminderBeDisplayed('autoplugNotEnabled')"
        class="flex items-center space-x-2"
      >
        <!-- Autoplug-not-enabled -->
        <router-link to="/powerups#autoplug">
          You're currently not using Hypefury's most powerful automation. Stop missing-out on free
          engagement and let Hypefury plug your offer under your popular tweets!
        </router-link>
        <div class="flex space-x-2 items-center">
          <span class="text-muted text-xs">–</span>
          <span
            class="cursor-pointer underline text-muted text-xs"
            @click="
              () => {
                hideFeature('autoplugNotEnabled');
                $eventStore.featureReminder.clickIgnore('autoplugNotEnabled');
              }
            "
            >Ignore</span
          >
        </div>
      </div>
      <div
        v-if="shouldFeatureReminderBeDisplayed('autoRetweetsNotEnabled')"
        class="flex items-center space-x-2"
      >
        <!-- autoRetweets-not-enabled -->
        <router-link to="/powerups#auto-retweeter">
          Give your tweets a boost by having Hypefury retweet them a few hours after they're posted!
        </router-link>
        <div class="flex space-x-2 items-center">
          <span class="text-muted text-xs">–</span>
          <span
            class="cursor-pointer underline text-muted text-xs"
            @click="
              () => {
                hideFeature('autoRetweetsNotEnabled');
                $eventStore.featureReminder.clickIgnore('autoRetweetsNotEnabled');
              }
            "
            >Ignore</span
          >
        </div>
      </div>
      <div
        v-if="shouldFeatureReminderBeDisplayed('addAccountManager')"
        class="flex items-center space-x-2"
      >
        <!-- VA/Account Manager feature -->
        <span>
          Did you know you could invite your VA or ghostwriter to write tweets for you?
        </span>
        <div class="flex space-x-2 items-center">
          <router-link
            to="/settings#account-managers"
            @click.native="$eventStore.featureReminder.addAccountManagerVisitSettingsPage()"
          >
            I'd like to
          </router-link>
          <span class="text-muted text-xs">–</span>
          <span
            class="cursor-pointer underline text-muted text-xs"
            @click="
              () => {
                hideFeature('addAccountManager');
                $eventStore.featureReminder.clickIgnore('addAccountManager');
              }
            "
            >Ignore</span
          >
        </div>
      </div>
      <div
        v-if="shouldFeatureReminderBeDisplayed('pinTabToBrowser')"
        class="flex items-center space-x-2"
      >
        <!-- Pin tab -->
        <a
          class="cursor-pointer"
          @click="
            () => {
              showPinTabModal = true;
              $eventStore.featureReminder.pinTabToBrowserClickDisplayTutorial();
            }
          "
        >
          Tip: pin this tab to your browser so you can quickly access Hypefury when you need it
        </a>
        <span class="text-muted text-xs">–</span>
        <div class="flex space-x-2 items-center">
          <span
            class="cursor-pointer underline text-muted text-xs"
            @click="
              () => {
                hideFeature('pinTabToBrowser');
                $eventStore.featureReminder.clickIgnore('pinTabToBrowser');
              }
            "
            >Ignore</span
          >
        </div>
        <modal
          @close="
            hideFeature('pinTabToBrowser');
            showPinTabModal = false;
          "
          :show="showPinTabModal"
          body-classes="p-0"
          modal-classes="modal-dialog-centered modal-lg image-modal"
        >
          <div slot="header" class="modal-title">
            How to pin a tab to your browser
          </div>
          <div>
            <img class="rounded-md mx-auto" src="/img/pintab.gif" />
          </div>
        </modal>
      </div>
      <div
        v-if="shouldFeatureReminderBeDisplayed('enableNotifications')"
        class="flex items-center space-x-2"
      >
        <!-- notifications-not-enabled -->
        <span class="cursor-pointer" @click="enableNotificationsThenHideAlert">
          Allow Hypefury to remind you of when your queue is empty
        </span>
        <div class="flex space-x-2 items-center">
          <span class="text-muted text-xs">–</span>
          <span
            class="cursor-pointer underline text-muted text-xs"
            @click="
              () => {
                hideFeature('enableNotifications');
                $eventStore.featureReminder.clickIgnore('enableNotifications');
              }
            "
            >Ignore</span
          >
        </div>
      </div>

      <div
        v-if="shouldFeatureReminderBeDisplayed('promoteHypefury')"
        class="flex items-center space-x-2"
      >
        <!-- Ask affiliate users to promote hypefury -->
        <span>
          Give Hypefury some love: do you want to automatically schedule a weekly recurrent post to promote Hypefury through your affiliate link?
        </span>
        <div class="flex space-x-2 items-center">
          <span @click="createHypefuryAffiliateCategoryAndScheduleIt" class="cursor-pointer underline text-muted text-xs">
            Yes!
          </span>
          <span class="text-muted text-xs">–</span>
          <span
            class="cursor-pointer underline text-muted text-xs"
            @click="hideFeature('promoteHypefury')"
            >No, thanks</span
          >
        </div>
      </div>

      <div
        v-if="shouldFeatureReminderBeDisplayed('upgradeToYearly')"
        class="flex items-center space-x-2"
      >
        <span>
          Save {{ parentUserProfile.isStripeCustomer ? "$" : ''}}{{ plan.gross * 2 }} {{ !parentUserProfile.isStripeCustomer ? plan.currency : '' }}
          by upgrading to the yearly plan!
        </span>
        <div class="flex space-x-2 items-center">
          <a class="cursor-pointer" @click="upgradeToYearlyThenDismissReminder">
           Give me the money!
          </a>
          <span class="text-muted text-xs">–</span>
          <span
            class="cursor-pointer underline text-muted text-xs"
            @click="
              () => {
                hideFeature('upgradeToYearly');
                $eventStore.featureReminder.clickIgnore('upgradeToYearly');
              }
            "
            >Not for now</span
          >
        </div>
      </div>

      <div
        v-if="shouldFeatureReminderBeDisplayed('highAutoplugPostingPercent')"
        class="flex items-center space-x-2"
      >
        <span>You are currently autoplugging more than half of your tweets. Would you consider lowering the autoplug frequency?</span>
        <router-link to="/powerups#autoplug">
         Yes
        </router-link>
        <span class="text-muted text-xs">–</span>
        <div class="flex space-x-2 items-center">
          <span
            class="cursor-pointer underline text-muted text-xs"
            @click="
              () => {
                hideFeature('highAutoplugPostingPercent');
                $eventStore.featureReminder.clickIgnore('highAutoplugPostingPercent');
              }
            "
            >Ignore</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import lodash from 'lodash';
  import { mapGetters, mapState } from 'vuex';
  import dao from '@/dao';
  import moment from 'moment';
  import controller from '../controller';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import EnableNotificationsMixin from '@/views/Mixins/EnableNotificationsMixin.vue';
  import BillingMixin from '@/views/Mixins/BillingMixin.vue';
  import CustomerStatusMixin from '../views/Mixins/CustomerStatusMixin.vue';

  const firebase = require('@/firebase');
  const config = require('@/config');

  let recentPosts = null;

  const features = {
    instagramInactivity: {
      conditions: async (userProfile) => {
        const isInstagramConnected = lodash.get(
          userProfile,
          'integrations.instagram.tokenInfo.accessToken',
          false
        );

        if (isInstagramConnected) {
          if (canShowMessage(userProfile, 'instagramInactivity', 7)) {
            const wereRecentPostsSharedOnInstagram = recentPosts
              ? recentPosts.some((post) => post.shareOnInstagram)
              : false;
            if (!wereRecentPostsSharedOnInstagram) {
              return 'instagramInactivity';
            }
          }
        } else if (canShowMessage(userProfile, 'instagramNotConnected', 21)) {
          return 'instagramNotConnected';
        }
      },
    },
    linkedInInactivity: {
      conditions: async (userProfile) => {
        const isLinkedInConnected = lodash.get(
          userProfile,
          'integrations.linkedin.tokenInfo',
          false
        );

        if (isLinkedInConnected) {
          if (canShowMessage(userProfile, 'linkedInInactivity', 7)) {
            const wereRecentPostsSharedOnLinkedIn = recentPosts
              ? recentPosts.some((post) => post.linkedIn)
              : false;
            if (!wereRecentPostsSharedOnLinkedIn) {
              return 'linkedInInactivity';
            }
          }
        } else if (canShowMessage(userProfile, 'linkedInNotConnected', 28)) {
          return 'linkedInNotConnected';
        }
      },
    },
    facebookInactivity: {
      conditions: async (userProfile) => {
        const isFacebookConnected = lodash.get(
          userProfile,
          'integrations.facebook.tokenInfo.accessToken',
          false
        );

        if (isFacebookConnected) {
          if (canShowMessage(userProfile, 'facebookInactivity', 7)) {
            const wereRecentPostsSharedOnFacebook = recentPosts
              ? recentPosts.some((post) => post.facebook)
              : false;
            if (!wereRecentPostsSharedOnFacebook) {
              return 'facebookInactivity';
            }
          }
        } else if (canShowMessage(userProfile, 'facebookNotConnected', 28)) {
          return 'facebookNotConnected';
        }
      },
    },
    gumroadSalesInactivity: {
      conditions: async (userProfile) => {
        const isGumroadConnected = lodash.get(
          userProfile,
          'integrations.gumroad.tokenInfo.accessToken'
        );

        if (isGumroadConnected) {
          if (canShowMessage(userProfile, 'gumroadSalesInactivity', 14)) {
            const lastMonth = moment().subtract(1, 'month').toDate();
            const gumroadSalesInLastMonth = await firebase.firestore
              .collection('gumroadSales')
              .where('createdAt', '>=', lastMonth)
              .limit(1)
              .get();

            if (gumroadSalesInLastMonth.docs.length !== 0) {
              return 'gumroadSalesInactivity';
            }
          }
        } else if (canShowMessage(userProfile, 'gumroadNotConnected', 28)) {
          return 'gumroadNotConnected';
        }
      },
    },
    autoRetweetsNotEnabled: {
      conditions: async (userProfile) => {
        const isAutoRetweetsEnabled = lodash.get(
          userProfile,
          'settings.conditionalRetweetsConditions.conditionalRetweetsEnabled',
          false
        );

        if (!isAutoRetweetsEnabled && canShowMessage(userProfile, 'autoRetweetsNotEnabled', 14)) {
          return 'autoRetweetsNotEnabled';
        }
      },
    },
    autoplugNotEnabled: {
      conditions: async (userProfile, { isAutoplugEnabled }) => {

        if (!isAutoplugEnabled && canShowMessage(userProfile, 'autoplugNotEnabled', 7)) {
          return 'autoplugNotEnabled';
        }
      },
    },
    evergreenRetweetsNotEnabled: {
      conditions: async (userProfile) => {
        const isAutoRetweetsEnabled = lodash.get(userProfile, 'autoRT', false);

        if (
          !isAutoRetweetsEnabled &&
          canShowMessage(userProfile, 'evergreenRetweetsNotEnabled', 7)
        ) {
          return 'evergreenRetweetsNotEnabled';
        }
      },
    },
    addAccountManager: {
      conditions: async (userProfile) => {
        const managers = lodash.get(userProfile, 'managers', []);
        if (managers.length === 0 && canShowMessage(userProfile, 'addAccountManager', 21)) {
          return 'addAccountManager';
        }
      },
    },
    followHypefuryOnTwitter: {
      conditions: async (userProfile, additionalValues) => {
        if (canShowMessage(userProfile, 'followHypefuryOnTwitter', 14)) {
          if (!additionalValues.isUserFollowingHypefuryOnTwitter) {
            return 'followHypefuryOnTwitter';
          }
        }
      },
    },
    enableNotifications: {
      conditions: async (userProfile) => {
        const areNotificationsEnabled = lodash.get(
          userProfile,
          'notifications.enabled',
          false
        );

        if (!areNotificationsEnabled) {
          if (canShowMessage(userProfile, 'enableNotifications', 7)) {
              return 'enableNotifications';
          }
        }
      },
    },
    pinTabToBrowser: {
      conditions: async (userProfile) => {
        const isDesktop = window.innerWidth >= 640;
        if (isDesktop && canShowMessage(userProfile, 'pinTabToBrowser', 21)) {
          return 'pinTabToBrowser';
        }
      },
    },
    promoteHypefury: {
      conditions: async (userProfile, additionalValues) => {
        const isUserAffiliate = additionalValues.userAffiliateDetails !== null;

        const doesUserHaveHypefuryAffiliateCategory =
          additionalValues.userCategoriesLoaded === false ||
          additionalValues.userCategories.some(
            (category) => category.name === 'Hypefury affiliate promotion'
          )

        if (
          isUserAffiliate &&
          !doesUserHaveHypefuryAffiliateCategory &&
          canShowMessage(userProfile, 'promoteHypefury', 3 * 30)
        ) {
          return 'promoteHypefury';
        }
      },
    },
    upgradeToYearly: {
      conditions: async (userProfile, additionalValues) => {
        if (additionalValues.isMonthlyPlan && canShowMessage(userProfile, 'upgradeToYearly', 14)) {
          return 'upgradeToYearly';
        }
      },
    },
    highAutoplugPostingPercent: {
      conditions: async (userProfile, { autoplugSettings, postedTweetsLikes, postedTweetsRetweets, isAutoplugEnabled }) => {
        if (isAutoplugEnabled && canShowMessage(userProfile, 'highAutoplugPostingPercent', 14) && postedTweetsLikes && postedTweetsRetweets) {
          const tweetsSameOrAboveThreshold =
            autoplugSettings.minFavorites !== null
              ? postedTweetsLikes.filter(
                  (tweetLikesCount) => tweetLikesCount >= autoplugSettings.minFavorites
                )
              : postedTweetsRetweets.filter(
                  (tweetRetweetCount) => tweetRetweetCount >= autoplugSettings.minRetweets
                );

          const percent = Math.ceil(
            (tweetsSameOrAboveThreshold.length /
              (autoplugSettings.minFavorites !== null
                ? postedTweetsLikes.length
                : postedTweetsRetweets.length)) *
              100
          );

          if (percent > 50) {
            return 'highAutoplugPostingPercent';
          }
        }
      },
    },
  };

  const getLastTenPosts = async (uid) => {
    const posts = await firebase.threadsCollection
      .where('type', '==', 'post')
      .where('source', '==', 'hypefury-app')
      .where('scheduled', '==', true)
      .where('user', '==', firebase.usersCollection.doc(uid))
      .orderBy('created_at', 'desc')
      .limit(10)
      .get();
    return posts.docs.map((doc) => doc.data());
  };

  const getPostsFromQueue = async (uid) => {
    const posts = await firebase.threadsCollection
      .where('user', '==', firebase.usersCollection.doc(uid))
      .where('deleted', '==', false)
      .where('time', '>=', new Date())
      .where('postNow', '==', false)
      .where('scheduled', '==', false)
      .get();
    return posts.docs.map((doc) => doc.data());
  };

  const getRecentPosts = async (uid) => {
    const [lastTenPosts, postsFromQueue] = await Promise.all([
      getLastTenPosts(uid),
      getPostsFromQueue(uid),
    ]);
    return [...lastTenPosts, ...postsFromQueue];
  };

  const getFeatureAlert = async (userProfile, currentUser, additionalValues) => {
    const featuresKeys = lodash.keys(features);
    const messages = [];

    for (let i = 0; i < featuresKeys.length; i++) {
      messages.push(await features[featuresKeys[i]].conditions(userProfile, currentUser, additionalValues));
    }

    return lodash.sample(lodash.without(messages, undefined));
  };

  function canShowMessage(userProfile, feature, periodInDays) {
    const lastTimeAppearedAt = lodash.get(
      userProfile,
      `featuresToRemindOf.${feature}.lastTimeDisplayedAt`,
      null
    );
    const canShowMessage = lastTimeAppearedAt
      ? moment.duration(moment().diff(lastTimeAppearedAt.toDate())).asDays() > periodInDays
      : true;
    return canShowMessage;
  }

  export default {
    name: 'features-reminder',
    data() {
      return {
        settingFeatureToDisplay: false,
        featureToDisplay: null,
        isFollowingHypefuryTwitterAccount: false,
        plan: {
          currency: null,
          net: null,
          gross: null,
          tax: null,
          parityDiscount: null,
          yearlyPrice: null,
          yearlyTax: null
        },
        showPinTabModal: false,
      };
    },
    async mounted() {
      if (!this.settingFeatureToDisplay) {
        recentPosts = await getRecentPosts(this.userProfile.uid);
        if (this.shouldFeatureReminderStayHidden()) return;
        this.settingFeatureToDisplay = true;
        this.featureToDisplay = await getFeatureAlert(this.userProfile, {
          userAffiliateDetails: this.userAffiliateDetails,
          userCategories: this.userCategories,
          userCategoriesLoaded: this.userCategoriesLoaded,
          isUserFollowingHypefuryOnTwitter: this.isUserFollowingHypefuryOnTwitter,
          isMonthlyPlan: this.isMonthlyPlan,
          autoplugSettings: this.autoplugSettings,
          postedTweetsLikes: this.postedTweetsLikes,
          postedTweetsRetweets: this.postedTweetsRetweets,
          isAutoplugEnabled: this.isAutoplugEnabled,
        });
        this.$eventStore.featureReminder.show(this.featureToDisplay);
        this.settingFeatureToDisplay = false;
      }
      if (
        this.isMonthlyPlan &&
        canShowMessage(this.userProfile, 'upgradeToYearly', 14) &&
        this.shouldFeatureReminderBeDisplayed('upgradeToYearly')
      ) {
        this.fetchPlans();
      }
    },
    methods: {
      upgradeToYearlyThenDismissReminder() {
        const callback = () => {
          this.featureToDisplay = null;
        };
        this.upgradeToYearly(callback);
      },
      async followHypefuryTwitterAccount() {
        try {
          this.$eventStore.featureReminder.followHypefuryTwitterAccountClickFollow();
          this.isFollowingHypefuryTwitterAccount = true;

          await controller.twitter.followUsers(
            [config.hypefuryAccountTwitterId],
            this.currentUser,
            this.userProfile.uid
          );

          this.$notify({
            type: 'success',
            message: 'You are now following the Hypefury twitter account',
          });
          return this.hideFeature('followHypefuryOnTwitter');
        } catch (error) {
          this.featureToDisplay = null;
          this.swalModal({
            title: 'Error',
            html: `An error has occurred while trying to follow the Hypefury account.`,
            type: 'warning',
          });
        } finally {
          this.isFollowingHypefuryTwitterAccount = false;
        }
      },
      shouldFeatureReminderBeDisplayed(feature) {
        return this.featureToDisplay === feature;
      },
      async enableNotificationsThenHideAlert() {
        this.$eventStore.featureReminder.enableNotificationsClickEnable();
        await this.enableNotifications();
        this.featureToDisplay = null;
      },
      hideFeature(dateField) {
        this.featureToDisplay = null;
        localStorage.setItem('feature_reminder_hidden_at', moment().format());
        return dao.userProfile.updateFeaturesToRemindOfDate(this.currentUser.uid, dateField);
      },
      showAutoplugModal() {
        this.featureToDisplay = null;
        this.$eventStore.featureReminder.autoplugNotEnabledShowAutoplugModal();
        this.$emit('show-autoplug-modal');
      },
      showConditionalRetweetsModal() {
        this.featureToDisplay = null;
        this.$eventStore.featureReminder.autoRetweetsNotEnabledShowConditionalRetweetsModal();
        this.$emit('show-conditional-retweets-modal');
      },
      shouldFeatureReminderStayHidden() {
        const featureReminderHiddenAt = localStorage.getItem('feature_reminder_hidden_at');
        return (featureReminderHiddenAt && moment().diff(featureReminderHiddenAt, 'minutes') < 60);
      },
      async createHypefuryAffiliateCategoryAndScheduleIt() {
        this.$eventStore.featureReminder.promoteHypefury();
        this.featureToDisplay = null;
        try {
          await controller.createHypefuryAffiliateCategoryAndScheduleIt(
            this.currentUser,
            this.userProfile.uid,
            this.userAffiliateDetails.referralLink
          );
          this.$notify({ type: 'success', message: 'Hypefury affiliate category was created successfully!' });
        } catch (error) {
          this.swalModal({
            title: 'Error',
            html: `An error has occurred while trying to create the Hypefury affiliate promotion category.`,
            type: 'warning',
          });
        }
      },
    },
    watch: {
      userProfile(val) {
        if (!val) return;
        this.$nextTick(async () => {
          if (!this.featureToDisplay && !this.settingFeatureToDisplay) {
            if (this.shouldFeatureReminderStayHidden()) return;
            this.settingFeatureToDisplay = true;
            setTimeout(async () => {
              this.featureToDisplay = await getFeatureAlert(this.userProfile, {
                userAffiliateDetails: this.userAffiliateDetails,
                userCategories: this.userCategories,
                userCategoriesLoaded: this.userCategoriesLoaded,
                isUserFollowingHypefuryOnTwitter: this.isUserFollowingHypefuryOnTwitter,
                isMonthlyPlan: this.isMonthlyPlan,
                autoplugSettings: this.autoplugSettings,
                postedTweetsLikes: this.postedTweetsLikes,
                postedTweetsRetweets: this.postedTweetsRetweets,
                isAutoplugEnabled: this.isAutoplugEnabled,
              });
              this.settingFeatureToDisplay = false;
            }, 2000);
          }
        });
      },
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile', parentUserProfile: 'getParentUserProfile' }),
      ...mapState(['userCategoriesLoaded', 'userCategories', 'userAffiliateDetails', 'isUserFollowingHypefuryOnTwitter', 'postedTweetsLikes', 'postedTweetsRetweets']),
      isMonthlyPlan() {
        return (
          this.isUserSubscribed(this.userProfile.customerStatus) &&
          this.userProfile.subscriptionInterval === 'month'
        );
      },
      isPlanPriceFetchedIfNecessary() {
        if (this.shouldFeatureReminderBeDisplayed('upgradeToYearly')) {
          return Boolean(this.plan.gross);
        }
        return true;
      },
      wasOnboardingChecklistDismissed() {
        return lodash.get(this.userProfile, 'onboardingChecklist.isDismissed', true);
      },
      autoplugSettings() {
        return lodash.get(
          this.userProfile,
          'settings.autoplugConditions',
          null
        );
      },
      isAutoplugEnabled() {
        return lodash.get(this.autoplugSettings, 'autoplugEnabled', false);
      },
    },
    mixins: [SwalModalMixin, EnableNotificationsMixin, BillingMixin, CustomerStatusMixin],
  };
</script>
