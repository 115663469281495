<script>
  import { mapGetters, mapState } from 'vuex';

  export default {
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      ...mapState(['schedule']),
    },
    name: 'drag-and-drop-mixin',
    data() {
      return {
        isDragEntered: false,
        isCategory: false,
      }
    },
    methods: {
      emitEvent(eventName, payload) {
        if (!this.isCategory) {
          return this.$parent.$emit(eventName, payload);
        } else {
          return this.$parent.$parent.$emit(eventName, payload);
        }
      },
      dropEmptySlot(slotTime) {
        this.$emit('dropped-empty-slot', slotTime);
        this.isDragEntered = false;
      },
      dropNonEmptySlot(slot) {
        this.emitEvent('dropped-non-empty-slot', slot);
        this.isDragEntered = false;
      },
      dragEnd() {
        this.emitEvent('dragged-thread-updated', null);
      },
      dragLeaveEmptySlot() {
        this.isDragEntered = false;
      },
      dragLeaveNonEmptySlot() {
        this.isDragEntered = false;
      },
      dragEnterEmptySlot(slot, event) {
        event.preventDefault();
        this.isDragEntered = true;
      },
      dragEnterNonEmptySlot(slot, event) {
        if (
          this.draggedPost &&
          ((this.isCategory && this.draggedPost.id !== slot.id) ||
            (!this.isCategory && !this.draggedPost.time.isSame(slot.time)))
        ) {
          event.preventDefault();
          this.isDragEntered = true;
        }
      },
      dragOver(_, event) {
        event.preventDefault();
      },
      dragStart(thread) {
        this.emitEvent('dragged-thread-updated', thread);
      },
    }
  }
</script>
