<script>
import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
import controller from '@/controller';

export default {
  methods: {
    openSetQueuedPostsAsEvergreenPrompt() {
      this.swalModal({
        title: 'Set all queued posts as evergreen',
        html: `<span>This will set all of your queued posts as evergreen. Do you wish to continue?</span>`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, go ahead',
        preConfirm: async () => {
          try {
            await controller.setAllQueuedPostsAsEvergreen(
              this.currentUser,
              this.userProfile
            );
            this.$notify({
              type: 'success',
              message: 'All your scheduled posts have been set as evergreen!',
            });
          } catch (error) {
            this.swalModal({
              title: 'Error',
              text: `An error occured while setting all your queued posts as evergreen.`,
              type: 'error',
            });
          }
        },
      });
    },
  },
  mixins:[SwalModalMixin]
};
</script>
