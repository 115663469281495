<template>
  <base-card class="relative" :bordered="false" lightBordered>
    <template #header>
      <div
        v-if="$slots.header"
        :class="['card-header group', { 'bordered-bottom': showBody, 'rounded-lg': !showBody }]"
      >
        <slot name="header" />
        <div v-if="canCollapse" class="flex items-center gap-x-5">
          <tooltip :content="isCollapsed ? 'Show Tweet' : 'Hide Tweet'">
            <new-button @click="isCollapsed = !isCollapsed" type="custom">
              <span class="sr-only">{{ isCollapsed ? 'Open Card' : 'Close Card' }}</span>
              <inline-svg
                src="/img/icons/chevron-down.svg"
                :class="[
                  'transition h-5 w-5 transform fill-current duration-500 ease-in-out hover:fill-main-color-100',
                  {
                    '-rotate-90': isCollapsed,
                    'rotate-1': !isCollapsed,
                  },
                ]"
              />
            </new-button>
          </tooltip>
          <slot name="actions" />
        </div>
        <slot name="actions" v-if="!canCollapse" />
      </div>
    </template>

    <template #body v-if="showBody">
      <div
        class="card-body bg-ds-background"
        data-cy="queue-card-body"
        :class="{ 'max-h-300 overflow-y-hidden rounded-b-lg': isExpandable && showExpand }"
        ref="body"
      >
        <slot></slot>
      </div>
    </template>

    <template #extra v-if="showBody">
      <div
        class="flex w-full items-end justify-between rounded-b-lg bg-ds-background px-8 pb-4"
        :class="isExpandable && showExpand && 'fade-effect absolute bottom-0 h-24'"
      >
        <slot name="extra"></slot>
        <div v-if="isExpandable">
          <new-button v-if="showExpand" @click="showExpand = false" type="secondary">
            <span>Show more</span>
          </new-button>

          <new-button v-else @click="showExpand = true" type="secondary">
            <span>Show less</span>
          </new-button>
        </div>
      </div>
    </template>
  </base-card>
</template>

<script>
  import BaseCard from '@/components/Cards/BaseCard.vue';

  export default {
    name: 'QueueCardContainer',
    components: {
      BaseCard,
    },
    props: {
      canCollapse: {
        type: Boolean,
        default: false,
      },
      hasBody: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        showExpand: true,
        isCollapsed: true,
        isExpandable: false,
      };
    },
    computed: {
      showBody() {
        if (!this.hasBody) {
          return false;
        }
        if (this.canCollapse) {
          return !this.isCollapsed;
        }
        return true;
      },
    },
    mounted() {
      const cardBody = this.$refs.body;
      this.$nextTick(() => {
        if (cardBody) {
          const cardBodyHeight = cardBody.clientHeight;
          const maxHeight = 350;
          if (cardBodyHeight > maxHeight && cardBodyHeight - maxHeight > 50) {
            this.isExpandable = true;
          }
        }
      });
    },
  };
</script>

<style lang="scss" scoped>
  .new-ui {
    .card-header {
      @apply flex w-full items-center justify-between rounded-t-lg px-3.5 py-2;
      ::v-deep h4 {
        @apply text-base font-medium leading-7;
        span {
          @apply text-base font-medium leading-7;
        }
      }
      @include sm {
        @apply px-6 py-2;
        ::v-deep h4 {
          @apply font-medium leading-9;
          span {
            @apply font-medium leading-9;
          }
        }
      }
    }

    .card-body {
      @apply max-w-610 px-4 py-3;
      @include sm {
        @apply px-8 py-6;
      }
    }

    .bordered {
      @apply border border-ds-outline-secondary;
    }

    .bordered-bottom {
      @apply border-b border-ds-outline-secondary;
    }

    &.mode--light {
      .card-header {
        @apply bg-ds-foreground;
        ::v-deep svg {
          @apply text-gray-50;
        }
        ::v-deep h4 {
          svg {
            @apply text-black;
          }
        }
      }
      .card-body {
        @apply text-black;
      }
    }

    &.mode--dark {
      .card-header {
        ::v-deep svg {
          @apply text-white;
        }
        ::v-deep h4 {
          svg {
            @apply text-white;
          }
        }
      }
      .card-body {
        @apply text-white;
      }
    }
  }
</style>
