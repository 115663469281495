<template>
  <tooltip :content="tooltipContent" :disabled="post.isSkipped">
    <queue-card-container :hasBody="false">
      <template #header>
        <div
          :data-cy="post.slotType !== 'recurrent' ? 'queue-card-evergreen' : 'queue-card-recurrent'"
          class="flex items-center gap-2"
        >
          <h4 data-cy="queue-card-time" :class="{ 'text-gray line-through': post.isSkipped }">
            {{ formatTimeWithUserLocale(postTimeMoment) }}
            <template v-if="post.slotType === 'recurrent'">
              <span :style="!post.isSkipped && `color: ${post.recurrentPost.category.color}`">
                Recurrent: {{ post.recurrentPost.category.name }}
              </span>
            </template>
            <template v-else>
              <inline-svg
                src="/img/icons/new/star.svg"
                class="mx-2 inline h-5 w-5 fill-success-100"
              />
              <span class="text-success-100">Evergreen Slot</span>
            </template>
          </h4>
        </div>

        <tooltip v-if="post.isSkipped" content="Restore recurrent slot">
          <new-button
            data-cy="queue-restore-post"
            @click="restoreRecurrentSlot(post)"
            type="light"
            icon
          >
            <inline-svg
              src="/img/icons/undo.svg"
              class="h-5 w-5 fill-current hover:fill-main-color-100"
            />
          </new-button>
        </tooltip>
      </template>
    </queue-card-container>
  </tooltip>
</template>

<script>
  import QueueSlotMixin from '@/views/Mixins/QueueSlotMixin.vue';

  export default {
    name: 'QueueRecurrentCard',
    mixins: [QueueSlotMixin],
    computed: {
      tooltipContent() {
        return this.post.slotType === 'recurrent'
          ? 'This is a recurrent post slot. You can add more or remove them from the Recurrent Posts page.'
          : `This is an Evergreen slot. At this time we'll automatically retweet something at random from your Evergreen Posts`;
      },
    },
  };
</script>
