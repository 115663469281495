<script>
  import { mapGetters } from 'vuex';
  import { v4 as publicIpv4 } from 'public-ip';
  import lodash from 'lodash';
  import controller from '@/controller';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import CustomerCountryMixin from '@/views/Mixins/CustomerCountryMixin.vue';
  import CustomerStatusMixin from './CustomerStatusMixin.vue';

  export default {
    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        parentUserProfile: 'getParentUserProfile',
      }),
    },
    data() {
      return {
        planPrices: null,
        plan: {
          currency: null,
          net: null,
          gross: null,
          tax: null,
          parityDiscount: null,
          yearlyPrice: null,
          yearlyTax: null,
        },
      };
    },
    methods: {
      async fetchPlans() {
        await this.getCustomerCountry();
        const customerIp = await publicIpv4();
        this.planPrices = await controller.getPlansPrices(this.currentUser, customerIp);

        const subscriptionDetails = await controller.getUserSubscriptionDetails(
          this.currentUser,
          customerIp
        );

        const { isParityDiscountAllowed } = this.PLANS[this.parentUserProfile.customerStatus];
        const parityDiscount = isParityDiscountAllowed
          ? lodash.get(subscriptionDetails, 'custom_data.parityDiscount.percentageOff')
          : null;

        const yearlyPlans = this.planPrices.filter((plan) => plan.subscription.interval === 'year');
        const monthlyPlans = this.planPrices.filter(
          (plan) => plan.subscription.interval === 'month'
        );

        const userPlan = monthlyPlans.find((plan) =>
          plan.product_title
            .toLowerCase()
            .includes(this.parentUserProfile.subscriptionTierName.toLowerCase())
        );

        this.plan.currency = userPlan.currency;
        this.plan.gross = userPlan.price.gross;
        this.plan.net = userPlan.price.net;
        this.plan.tax = userPlan.price.tax;

        const yearlyPlan = yearlyPlans.find((plan) =>
          plan.product_title
            .toLowerCase()
            .includes(this.parentUserProfile.subscriptionTierName.toLowerCase())
        );

        const taxPercentage = (100 * yearlyPlan.price.tax) / yearlyPlan.price.net;

        if (parityDiscount) {
          this.plan.parityDiscount = parityDiscount;
          const discount = Number(parityDiscount);
          const amountOff = (discount / 100) * yearlyPlan.price.net;
          const yearlyPlanPrice = Number((yearlyPlan.price.net - amountOff).toFixed(2));
          this.plan.yearlyPrice = yearlyPlanPrice;
          this.plan.yearlyTax = ((taxPercentage / 100) * yearlyPlanPrice).toFixed(2);
        } else {
          const yearlyPlanPrice = Number(yearlyPlan.price.net.toFixed(2));
          this.plan.yearlyPrice = yearlyPlanPrice;
          this.plan.yearlyTax = ((taxPercentage / 100) * yearlyPlanPrice).toFixed(2);
        }
      },
      async upgradeToYearly(callback) {
        const isStripeCustomer = Boolean(this.parentUserProfile.isStripeCustomer);
        const newPriceInfo = this.plan.parityDiscount
          ? { recurring_price: this.plan.yearlyPrice, quantity: 1, currency: this.plan.currency }
          : {};
        const discountMessage = this.plan.parityDiscount
          ? `<span class='bg-green-500 p-2 rounded-lg'><b>Your ${this.plan.parityDiscount}% discount for ${this.countryName} has been applied.</b></span><br/><br/>`
          : '';

        this.swalModal({
          title: `Upgrade to the yearly plan and save ${isStripeCustomer ? '$' : ''}${
            isStripeCustomer ? this.plan.gross * 2 : `${this.plan.gross * 2} ${this.plan.currency}`
          }`,
          html:
            `${discountMessage}` +
            `This will <em>start a new yearly subscription</em>.<br>` +
            `You will be billed 10 months <b>(${isStripeCustomer ? '$' : ''}${
              isStripeCustomer
                ? this.plan.gross * 10
                : `${this.plan.yearlyPrice} ${this.plan.currency} + VAT ${this.plan.yearlyTax} ${this.plan.currency} `
            })</b>, instead of 12, saving you <b>${isStripeCustomer ? '$' : ''}${
              isStripeCustomer
                ? this.plan.gross * 2
                : `${this.plan.gross * 2} ${this.plan.currency}`
            }</b>.<br>` +
            `Do you want to go ahead?`,
          showCancelButton: true,
          cancelButtonText: 'No thanks',
          confirmButtonText: 'Yes, save some money please!',
          preConfirm: async () => {
            this.$eventStore.featureReminder.upgradeToYearlyUpgraded();

            if (callback) {
              callback();
            }

            try {
              this.parentUserProfile.isStripeCustomer
                ? await controller.upgradeStripeUserToYearly(this.currentUser)
                : await controller.upgradeUserToYearly(this.currentUser, newPriceInfo);
              this.$notify({
                type: 'success',
                message: 'You have been upgraded to the yearly plan successfully!',
              });
              this.$eventStore.updateUserSubscription.updateToYearlyPlan();
            } catch (error) {
              this.swalModal({
                title: 'Sorry',
                text: `An error has occurred while trying to upgrade you to the yearly plan.`,
                type: 'warning',
              });
            }
          },
        });
      },
    },
    mixins: [SwalModalMixin, CustomerCountryMixin, CustomerStatusMixin],
  };
</script>
