<script>
  import { formatTweet } from '../../util/formatTweet';
  import { getInstagramCaption } from '@/../functions/src/util/getInstagramCaption';

  export default {
    methods: {
      getPostStatus(thread) {
        if (thread.source === 'linkedin-post') {
          return thread.tweets[0].status;
        } else if (thread.source === 'instagram-post') {
          const { status, hashtags } = thread.tweets[0];
          return getInstagramCaption(status, hashtags);
        } else {
          return formatTweet(thread.tweets[0].status);
        }
      },
    },
  };
</script>
