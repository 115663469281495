<template>
  <div>
    <portal to="addSlot">
      <new-button @click="isAddSlotModalVisible = true" data-cy="schedule-add-slot">
        Add slot
      </new-button>
    </portal>
    <div class="grid gap-6">
      <new-alert
        v-if="showMoveAllScheduledPostsToTopMessage"
        class="flex flex-col items-center gap-y-4 md:flex-row md:justify-between"
      >
        <div class="flex items-center space-x-4 pr-6">
          <inline-svg class="w-8" src="/img/icons/info.svg"></inline-svg>
          <span>
            Would you like Hypefury to update the times of the currently scheduled posts to the
            nearest available slot?
          </span>
        </div>

        <div class="flex items-center space-x-4">
          <new-button :disabled="isMovingAllQueuedPostsToTop" @click="moveAllQueuedPostsToTop">
            Update
          </new-button>
          <new-button
            :disabled="isMovingAllQueuedPostsToTop"
            @click="refreshSchedule = false"
            type="secondary"
          >
            Cancel
          </new-button>
        </div>
      </new-alert>

      <new-button
        @click="isNaturalScheduleModalVisible = true"
        class="w-full"
        :disabled="allSlotsCount === 0"
        data-cy="schedule-naturalize"
      >
        Naturalize Schedule
      </new-button>
      <div data-cy="posting-schedule-day" :key="day" v-for="(timeSlots, day) in fullSchedule">
        <span class="mb-4 block text-base font-medium capitalize lg:text-lg">{{ day }}</span>
        <div class="grid gap-6 md:gap-4">
          <div
            v-for="timeSlot in timeSlots"
            :key="timeSlot.id"
            class="flex flex-wrap items-start justify-evenly gap-2"
            data-cy="schedule-time-slot"
          >
            <div
              class="border app-queue-slot-schedule flex-grow flex max-w-32 items-start justify-center rounded-xl border-gray-60 bg-white px-3 py-3 text-base lg:text-lg"
              data-cy="schedule-time"
            >
              {{ timeSlot.formatted }}
            </div>
            <div
              class="border app-queue-slot-schedule flex-grow flex items-center justify-center gap-3 rounded-xl border-gray-60 bg-white px-3 py-3 md:flex-grow-0"
            >
              <span
                class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full"
                data-cy="schedule-tweet-icon"
                :class="[
                  getSlotType(timeSlot).type === 'twitter'
                    ? 'bg-main-color-100 text-white'
                    : 'app-queue-slot-social-media bg-gray-60 text-white',
                ]"
                @click="getSlotType(timeSlot).type !== 'twitter' ? updateSlotType(timeSlot) : null"
              >
                <inline-svg
                  src="/img/icons/new/socials/twitter.svg"
                  class="h-3.5 w-3.5 fill-current"
                />
              </span>
              <span
                class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full"
                data-cy="schedule-linkedin-icon"
                :class="[
                  getSlotType(timeSlot).type === 'linkedin'
                    ? 'bg-main-color-100 text-white'
                    : 'app-queue-slot-social-media bg-gray-60 text-white',
                ]"
                @click="
                  getSlotType(timeSlot).type !== 'linkedin'
                    ? updateSlotType(timeSlot, 'linkedin')
                    : null
                "
              >
                <inline-svg
                  class="h-3 w-3 fill-current"
                  src="/img/icons/new/socials/linkedin.svg"
                />
              </span>
              <span
                class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full"
                data-cy="schedule-facebook-icon"
                :class="[
                  getSlotType(timeSlot).type === 'facebook'
                    ? 'bg-main-color-100 text-white'
                    : 'app-queue-slot-social-media bg-gray-60 text-white',
                ]"
                @click="
                  getSlotType(timeSlot).type !== 'facebook'
                    ? updateSlotType(timeSlot, 'facebook')
                    : null
                "
              >
                <inline-svg
                  class="h-3 w-3 fill-current"
                  src="/img/icons/new/socials/facebook.svg"
                />
              </span>
              <span
                class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full"
                data-cy="schedule-instagram-icon"
                :class="[
                  getSlotType(timeSlot).type === 'instagram'
                    ? 'bg-main-color-100 text-white'
                    : 'app-queue-slot-social-media bg-gray-60 text-white',
                ]"
                @click="
                  getSlotType(timeSlot).type !== 'instagram'
                    ? updateSlotType(timeSlot, 'instagram')
                    : null
                "
              >
                <inline-svg
                  class="h-3 w-3 fill-current"
                  src="/img/icons/new/socials/instagram.svg"
                />
              </span>
              <span
                class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full"
                data-cy="schedule-threads-icon"
                :class="[
                  getSlotType(timeSlot).type === 'threads'
                    ? 'bg-main-color-100 text-white'
                    : 'app-queue-slot-social-media bg-gray-60 text-white',
                ]"
                @click="
                  getSlotType(timeSlot).type !== 'threads'
                    ? updateSlotType(timeSlot, 'threads')
                    : null
                "
              >
                <inline-svg
                  class="h-3 w-3 stroke-current"
                  src="/img/icons/new/socials/threads.svg"
                />
              </span>
            </div>
            <div
              v-if="getSlotType(timeSlot).type === 'twitter'"
              class="border app-queue-slot-schedule group relative min-w-full flex-1 rounded-xl border-gray-60 bg-white px-4 py-3 md:min-w-0"
              @mouseover="slotActions = true"
              @mouseleave="onMouseLeave"
              data-cy="schedule-actions"
            >
              <span
                v-if="!slotActions && !changeType"
                class="text-base"
                :class="[
                  {
                    'text-green-500': getSlotType(timeSlot).subType === 'evergreen',
                    '': getSlotType(timeSlot).subType === 'normal',
                  },
                ]"
                :style="
                  getSlotType(timeSlot).subType === 'recurrent'
                    ? `color: ${getSlotType(timeSlot).category.color}`
                    : ''
                "
                >{{
                  getSlotType(timeSlot).subType === 'recurrent'
                    ? `Recurrent: ${getSlotType(timeSlot).category.name}`
                    : capitalize(getSlotType(timeSlot).subType)
                }}</span
              >
              <div v-else class="flex items-center gap-3">
                <span
                  class="flex cursor-pointer items-center gap-2 text-base text-main-color-100"
                  @click="changeType = timeSlot.id"
                  data-cy="schedule-change-type"
                >
                  <inline-svg
                    src="/img/icons/new/refresh.svg"
                    class="h-4 w-4 stroke-main-color-100"
                  />
                  Change type
                </span>
                <tooltip
                  content="Can't delete: At least one Twitter slot is required."
                  :disabled="
                    getSlotType(timeSlot).type !== 'twitter' ||
                    (hasRemainingTwitterSlots && getSlotType(timeSlot).type === 'twitter')
                  "
                >
                  <button
                    @click="deleteSlot(timeSlot)"
                    :disabled="
                      !hasRemainingTwitterSlots && getSlotType(timeSlot).type === 'twitter'
                    "
                    class="flex cursor-pointer items-center gap-2 text-base text-danger-100"
                    data-cy="schedule-delete-slot"
                  >
                    <inline-svg src="/img/icons/new/delete.svg" class="h-4 w-4 stroke-danger-100" />
                    Delete slot
                  </button>
                </tooltip>
              </div>
              <div
                v-if="changeType === timeSlot.id"
                class="border without-scrollbar app-queue-slot-change-dropdown absolute left-0 z-50 max-h-300 w-full overflow-y-auto rounded-xl border-gray-60 bg-white p-4"
                style="top: calc(100% + 10px)"
              >
                <div data-cy="schedule-types" class="grid gap-4">
                  <span class="cursor-pointer text-base" @click="updateSlotType(timeSlot)"
                    >Normal</span
                  >
                  <span
                    class="cursor-pointer text-base text-green-500"
                    @click="updateSlotType(timeSlot, 'evergreen')"
                    >Evergreen</span
                  >
                  <span
                    @click="updateSlotType(timeSlot, 'recurrent', category.id)"
                    v-for="category in userCategories"
                    :key="category.id"
                    class="cursor-pointer text-base"
                    :style="`color: ${category.color}`"
                    >Recurrent: {{ category.name }}</span
                  >
                </div>
              </div>
            </div>
            <div
              v-else
              class="border flex-grow app-queue-slot-schedule group relative min-w-full flex-1 flex-shrink-0 rounded-xl border-gray-60 bg-white px-3 py-3 md:min-w-0"
              data-cy="schedule-actions"
            >
              <div class="flex items-center gap-4">
                <tooltip
                  content="Can't delete: At least one Twitter slot is required."
                  :disabled="
                    getSlotType(timeSlot).type !== 'twitter' ||
                    (hasRemainingTwitterSlots && getSlotType(timeSlot).type === 'twitter')
                  "
                >
                  <button
                    @click="deleteSlot(timeSlot)"
                    :disabled="
                      !hasRemainingTwitterSlots && getSlotType(timeSlot).type === 'twitter'
                    "
                    class="flex cursor-pointer items-center gap-2 text-base text-danger-100"
                    data-cy="schedule-delete-slot"
                  >
                    <inline-svg src="/img/icons/new/delete.svg" class="h-4 w-4 stroke-danger-100" />
                    Delete slot
                  </button>
                </tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="changeType"
      @click="
        changeType = null;
        slotActions = false;
      "
      class="fixed inset-0 cursor-pointer"
    />

    <add-slot-modal
      v-if="isAddSlotModalVisible"
      @close="isAddSlotModalVisible = false"
      @update-type-of-last-updated-slot="(value) => (typeOfLastUpdatedSlot = value)"
      @refresh-schedule="() => (refreshSchedule = true)"
    />
    <natural-schedule-modal
      v-if="isNaturalScheduleModalVisible"
      @close="isNaturalScheduleModalVisible = false"
      @naturalize-schedule="
        ({ moveAllQueuedPostsToTop }) => naturalizeSchedule(moveAllQueuedPostsToTop)
      "
    />
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import moment from 'moment';
  import lodash from 'lodash';
  import { ScheduleForSettings } from '@/models/ScheduleForSettings';
  import AddSlotModal from '@/views/Modals/AddSlotModal.vue';
  import NaturalScheduleModal from '@/views/Modals/NaturalScheduleModal.vue';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import dao from '@/dao';
  import ScheduleMixin from '../views/Mixins/ScheduleMixin.vue';
  import BreakpointsMixin from '@/views/Mixins/BreakpointsMixin.vue';

  const fb = require('@/firebase');
  export default {
    components: { AddSlotModal, NaturalScheduleModal },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      ...mapState(['userRecurrentPosts', 'userCategories']),
      processedRecurrentPosts() {
        return this.userRecurrentPosts
          .filter((p) => p.category)
          .map((post, idx) => {
            // For recurrent posts 7 is Sunday, but for the schedule it's 0
            const day = post.day === 7 ? 0 : post.day;
            const [hour, minute] = post.time.split(':');
            const id = `${this.weekdays[day]}-${hour}-${minute}-recurrent-${idx}`;
            const formatted = moment(`${hour}:${minute}`, 'HH:mm').format('h:mm A');

            return {
              day,
              id,
              hour: Number(hour),
              minute: Number(minute),
              formatted,
              subType: 'recurrent',
              category: {
                name: post.category.name,
                id: post.category.id,
                color: post.category.color,
              },
              postId: post.id,
            };
          });
      },
      fullSchedule() {
        const schedule = Object.entries(this.scheduleForView);

        const fullSchedule = schedule.reduce((acc, day) => {
          const [dayName, slots] = day;
          const slotsToAdd = this.processedRecurrentPosts.filter((post) => {
            return this.weekdays[post.day] === dayName;
          });

          return {
            ...acc,
            [dayName]: [...slots, ...slotsToAdd].sort((a, b) => {
              if (a.hour === b.hour) {
                return a.minute - b.minute;
              } else {
                return a.hour - b.hour;
              }
            }),
          };
        }, {});

        return fullSchedule;
      },
      hasRemainingTwitterSlots() {
        const slots = lodash.flatten(Object.values(this.scheduleForView));
        const twitterSlots = slots.filter((slot) => this.getSlotType(slot).type === 'twitter');
        return twitterSlots.length > 1;
      },
    },
    data() {
      return {
        weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        slotActions: false,
        changeType: null,
        isAddSlotModalVisible: false,
      };
    },
    mounted() {
      if (this.isTabletOrMobile) {
        this.slotActions = true;
      }
    },
    methods: {
      capitalize(str) {
        return str
          .split(' ')
          .map((word) => lodash.capitalize(word))
          .join(' ');
      },
      getSlotType(slot) {
        if (!slot.type && !slot.subType) {
          return { type: 'twitter', subType: 'normal' };
        } else if (slot.type === 'evergreen') {
          return { type: 'twitter', subType: 'evergreen' };
        } else if (slot.subType === 'recurrent') {
          return { type: 'twitter', subType: 'recurrent', category: slot.category };
        } else {
          return { type: slot.type };
        }
      },
      // TODO for Mahdi: Simplify this later
      async updateSlotType(slot, newType, newCategory) {
        const time = moment({ hour: slot.hour, minute: slot.minute }).format('HH:mm');
        this.changeType = null;

        if (newType === 'recurrent') {
          if (slot.subType === 'recurrent') {
            await dao.recurrentPosts.update(slot.postId, slot.day, time, newCategory);
          } else {
            const categoryRef = fb.categoriesCollection.doc(newCategory);
            const userRef = fb.usersCollection.doc(this.userProfile.uid);

            const doc = {
              day: slot.day,
              time,
              category: categoryRef,
              userRef,
            };

            const newScheduleForDB =
              this.userProfile.schedule.removeSlotAndReturnScheduleForDB(slot);

            await Promise.all([
              dao.recurrentPosts.createMultiple([doc]),
              fb.usersCollection.doc(this.userProfile.uid).update(newScheduleForDB),
            ]);
          }
          this.$notify({ type: 'success', message: 'Schedule updated successfully!' });
          return;
        }

        const operations = [];
        if (slot.subType === 'recurrent') {
          if (this.userProfile.schedule.getScheduleForDB()[slot.day].includes(time)) {
            return this.swalModal({
              title: 'Sorry',
              html: 'You already have a slot at this time.',
              type: 'warning',
            });
          }
          operations.push(dao.recurrentPosts.delete(slot.postId));
        }

        if (newType === 'evergreen') {
          const dayToArray = this.userProfile.schedule.getScheduleForDB()[slot.day].split(',');
          const evergreenSlotsInDay = dayToArray.filter((timeSlot) =>
            timeSlot.includes('evergreen')
          );

          if (evergreenSlotsInDay.length === 1) {
            return this.swalModal({
              title: 'Sorry',
              html: "You can't add more than 1 Evergreen slot per day.",
              type: 'warning',
            });
          }
        }

        if (newType === 'instagram') {
          const dayToArray = this.userProfile.schedule.getScheduleForDB()[slot.day].split(',');
          const instagramSlotsInDay = dayToArray.filter((timeSlot) =>
            timeSlot.includes('instagram')
          );

          if (instagramSlotsInDay.length === 5) {
            return this.swalModal({
              title: 'Sorry',
              html: "You can't add more than 5 Instagram slots per day.",
              type: 'warning',
            });
          }
        }

        const scheduleAfterRemoval = new ScheduleForSettings(
          this.userProfile.schedule.removeSlotAndReturnScheduleForDB(slot).schedule
        );

        const newScheduleForDB = scheduleAfterRemoval.getScheduleForDBWithNewSlot({
          ...slot,
          type: newType,
        });

        try {
          operations.push(fb.usersCollection.doc(this.userProfile.uid).update(newScheduleForDB));
          await Promise.all(operations);
          this.typeOfLastUpdatedSlot = newType;
          this.refreshSchedule = true;
          this.$notify({ type: 'success', message: 'Schedule updated successfully!' });
        } catch {
          return this.swalModal({
            title: 'Sorry',
            html: 'An error occurred while updating the schedule.',
            type: 'warning',
          });
        }
      },
      async deleteSlot(slot) {
        const newScheduleForDB = this.userProfile.schedule.removeSlotAndReturnScheduleForDB(slot);

        try {
          if (slot.subType === 'recurrent') {
            await dao.recurrentPosts.delete(slot.postId);
          } else {
            await fb.usersCollection.doc(this.userProfile.uid).update(newScheduleForDB);
            this.typeOfLastUpdatedSlot = slot.type;
            this.refreshSchedule = true;
          }
          this.$notify({ type: 'success', message: 'Schedule updated successfully!' });
        } catch (error) {
          this.$notify({
            type: 'error',
            message: 'Something went wrong while updating the schedule!',
          });
        }
      },
      onMouseLeave() {
        if (!this.isTabletOrMobile) {
          this.slotActions = false;
        }
      },
    },
    mixins: [SwalModalMixin, ScheduleMixin, BreakpointsMixin],
  };
</script>
