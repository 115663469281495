<template>
  <base-modal @close="$emit('close')" :loading="isSubmitting">
    <div class="rounded-3xl p-8 sm:w-591">
      <div class="grid gap-6">
        <div class="flex items-center gap-4">
          <div class="flex h-12 w-12 items-center justify-center rounded-full bg-main-color-100">
            <inline-svg src="/img/icons/date-icon.svg" class="h-6 w-6 fill-white" />
          </div>
          <span data-cy="add-slot-header" class="text-2xl font-bold">Add a slot</span>
        </div>
        <div>
          <span class="text-badge-error" v-if="!isSelectedTimeSlotValid"
            >Slot(s) are already filled for your current selection</span
          >

          <span class="app-modal-text mb-2 block text-base font-medium text-gray-80">Platform</span>
          <div class="flex items-center gap-4">
            <span
              @click="selectedSocialMedia = 'twitter'"
              class="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-white"
              :class="[
                selectedSocialMedia === 'twitter'
                  ? 'bg-main-color-100'
                  : 'app-queue-slot-social-media bg-gray-60 text-white',
              ]"
              data-cy="add-slot-twitter"
            >
              <inline-svg src="/img/icons/twitter.svg" class="h-3.5 w-3.5" />
            </span>
            <span
              @click="selectedSocialMedia = 'linkedin'"
              class="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-white"
              :class="[
                selectedSocialMedia === 'linkedin'
                  ? 'bg-main-color-100'
                  : 'app-queue-slot-social-media bg-gray-60 text-white',
              ]"
              data-cy="add-slot-linkedin"
            >
              <inline-svg class="h-3.5 w-3.5 fill-current" src="/img/icons/linkedin-icon.svg" />
            </span>
            <span
              @click="selectedSocialMedia = 'facebook'"
              class="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-white"
              :class="[
                selectedSocialMedia === 'facebook'
                  ? 'bg-main-color-100'
                  : 'app-queue-slot-social-media bg-gray-60 text-white',
              ]"
              data-cy="add-slot-facebook"
            >
              <inline-svg class="h-4 w-4 fill-current" src="/img/icons/facebook-icon.svg" />
            </span>
            <span
              @click="selectedSocialMedia = 'instagram'"
              class="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-white"
              :class="[
                selectedSocialMedia === 'instagram'
                  ? 'bg-main-color-100'
                  : 'app-queue-slot-social-media bg-gray-60 text-white',
              ]"
              data-cy="add-slot-instagram"
            >
              <inline-svg class="h-4 w-4 fill-current" src="/img/icons/instagram-icon.svg" />
            </span>
            <span
              @click="selectedSocialMedia = 'threads'"
              class="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-white"
              :class="[
                selectedSocialMedia === 'threads'
                  ? 'bg-main-color-100'
                  : 'app-queue-slot-social-media bg-gray-60 text-white',
              ]"
              data-cy="add-slot-threads"
            >
              <inline-svg class="h-4 w-4 stroke-current" src="/img/icons/new/socials/threads.svg" />
            </span>
          </div>
        </div>
        <div>
          <label for="days" class="app-modal-text mb-2 block text-base font-medium text-gray-80"
            >Days</label
          >
          <base-select
            :options="daysOptions"
            labelAttribute="name"
            valueAttribute="value"
            v-model="newTimeSlotDay"
            placeholder="Select an Option"
            data-cy="add-slot-day-select"
          />
        </div>
        <div>
          <label for="time" class="app-modal-text mb-2 block text-base font-medium text-gray-80"
            >Time</label
          >
          <base-text-input data-cy="add-slot-time-input" v-model="newTimeSlotTime" type="time" />
        </div>
        <div class="flex items-center justify-between">
          <new-button
            @click="$emit('close')"
            type="light"
            class="text-main-color-30"
            data-cy="add-slot-close"
          >
            Close
          </new-button>
          <new-button
            data-cy="add-slot-save"
            :disabled="!isSelectedTimeSlotValid"
            @click="addTimeSlot"
          >
            Save
          </new-button>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import ScheduleMixin from '@/views/Mixins/ScheduleMixin.vue';
  const fb = require('@/firebase');
  import normalizeStringNumber from '@/util/normalizeStringNumber';

  export default {
    data() {
      return {
        selectedSocialMedia: 'twitter',
        daysOptions: [
          { name: 'Every day', value: 'Every day' },
          { name: 'Weekdays', value: 'Weekdays' },
          { name: 'Weekends', value: 'Weekends' },
          { name: 'Monday', value: '1' },
          { name: 'Tuesday', value: '2' },
          { name: 'Wednesday', value: '3' },
          { name: 'Thursday', value: '4' },
          { name: 'Friday', value: '5' },
          { name: 'Saturday', value: '6' },
          { name: 'Sunday', value: '0' },
        ],
        isSubmitting: false,
      };
    },
    methods: {
      async addTimeSlot() {
        if (!this.isSelectedTimeSlotValid) return;

        const [hour, minute] = this.splittedTimeSlotTime;

        const timeSlot = {
          day: this.newTimeSlotDay,
          hour: parseInt(normalizeStringNumber(hour)),
          minute: parseInt(normalizeStringNumber(minute)),
        };

        if (['instagram', 'linkedin', 'facebook', 'threads'].includes(this.selectedSocialMedia)) {
          timeSlot['type'] = this.selectedSocialMedia;
        }

        const scheduleForDB = this.userProfile.schedule.getScheduleForDBWithNewSlot(timeSlot);

        if (['instagram'].includes(timeSlot.type)) {
          const scheduleToArray = scheduleForDB.schedule.map((day) => day.split(','));
          const instagramSlotsInSchedule = scheduleToArray.map((day) =>
            day.filter((timeSlot) => timeSlot.includes('instagram')),
          );

          if (instagramSlotsInSchedule.some((day) => day.length > 5)) {
            return this.swalModal({
              title: 'Sorry',
              html: "You can't add more than 5 Instagram slots per day.",
              type: 'warning',
            });
          }
        }

        this.isSubmitting = true;
        try {
          await fb.usersCollection.doc(this.userProfile.uid).update(scheduleForDB);
          this.$emit('update-type-of-last-updated-slot', timeSlot.type);
          this.$notify({ type: 'success', message: 'Schedule updated!' });
          this.$emit('close');
        } catch {
          return this.swalModal({
            title: 'Error',
            html: 'An error occurred while updating your schedule.',
            type: 'warning',
          });
        } finally {
          this.isSubmitting = false;
        }
      },
    },
    mixins: [SwalModalMixin, ScheduleMixin],
  };
</script>
