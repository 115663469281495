<script>
  import { mapGetters } from 'vuex';
  import lodash from 'lodash';
  import dao from '@/dao';
  import MoveAllPostsToTopMixin from '@/views/Mixins/MoveAllPostsToTopMixin.vue';
  export default {
    data() {
      return {
        isMovingAllQueuedPostsToTop: false,
        refreshSchedule: false,
        newTimeSlotDay: 'Every day',
        newTimeSlotTime: '12:30',
        typeOfLastUpdatedSlot: null,
        isNaturalScheduleModalVisible: false,
      };
    },
    computed: {
      ...mapGetters({
        userProfile: 'getUserProfile',
      }),
      scheduleForView() {
        return this.userProfile.schedule.getScheduleForView();
      },
      allSlotsCount() {
        let count = 0;

        Object.entries(this.scheduleForView).forEach(([, slots]) => {
          count += slots.length;
        });

        return count;
      },
      slotsCount() {
        let count = 0;

        Object.entries(this.scheduleForView).forEach(([, slots]) => {
          count += slots.filter((slot) => slot.type !== 'evergreen').length;
        });

        return count;
      },
      isSelectedTimeSlotValid() {
        const doesSlotAlreadyExist = () => {
          const weekdays = [1, 2, 3, 4, 5];
          const weekends = [0, 6];

          switch (this.newTimeSlotDay) {
            case 'Every day':
              return this.userProfile.schedule
                .getScheduleForDB()
                .every((day) => day.includes(this.newTimeSlotTime));
            case 'Weekdays':
              return weekdays.every((day) =>
                this.userProfile.schedule.getScheduleForDB()[day].includes(this.newTimeSlotTime)
              );
            case 'Weekends':
              return weekends.every((day) =>
                this.userProfile.schedule.getScheduleForDB()[day].includes(this.newTimeSlotTime)
              );
            default:
              return this.userProfile.schedule
                .getScheduleForDB()[this.newTimeSlotDay]
                .includes(this.newTimeSlotTime);
          }
        };

        return (
          this.newTimeSlotTime !== '' &&
          this.splittedTimeSlotTime.length >= 2 &&
          this.splittedTimeSlotTime.every((time) => !isNaN(time)) &&
          !doesSlotAlreadyExist()
        );
      },
      splittedTimeSlotTime() {
        return this.newTimeSlotTime.split(':');
      },
      currentlyScheduledPostsCount() {
        return lodash.get(this.userProfile, 'currentlyScheduledPostsCount');
      },
      showMoveAllScheduledPostsToTopMessage() {
        /**
         * Posts can only be moved to top when slotsCount * 52 >= currentlyScheduledPostsCount.
         *
         * Example: Since we only allow scheduling for up to a year ahead, if user has 60 scheduled posts,
         * and only 1 slot in his schedule (say Monday 12:30 * 52 weeks in the year), we won't be able
         * to accomodate all of the 60 posts in the generated slots (52). This means the user needs
         * to add more slots to the Schedule.
         */
        return (
          !['instagram', 'linkedin', 'facebook'].includes(this.typeOfLastUpdatedSlot) &&
          this.refreshSchedule &&
          this.slotsCount * 52 >= this.currentlyScheduledPostsCount
        );
      },
    },
    methods: {
      async moveAllQueuedPostsToTop() {
        this.isMovingAllQueuedPostsToTop = true;
        await this.moveAllPostsToTop();
        this.isMovingAllQueuedPostsToTop = false;
        this.refreshSchedule = false;
      },
      async naturalizeSchedule(moveAllQueuedPostsToTop) {
        const naturalizedSchedule = this.userProfile.schedule.naturalize();
        await dao.userProfile.updateSchedule(this.userProfile.uid, naturalizedSchedule);
        this.$notify({
          type: 'success',
          message: 'Your schedule has been successfully naturalized!',
        });

        if (moveAllQueuedPostsToTop) {
          await this.moveAllQueuedPostsToTop();
        }
      },
    },
    mixins: [MoveAllPostsToTopMixin],
  };
</script>
