<script>
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import controller from '@/controller';

  export default {
    methods: {
      async moveAllPostsToTop() {
        try {
          await controller.moveAllQueuedPostsToTop(this.currentUser, this.userProfile);
          this.$notify({
            type: 'success',
            message: 'All your scheduled tweets have been moved to the top!',
          });
        } catch (error) {
          this.swalModal({
            title: 'Sorry',
            text: 'An error has ocurred while moving your tweets to the top.',
            type: 'warning',
          });
        }
      },
    },
    mixins: [SwalModalMixin],
  };
</script>
